import React, { useState } from "react";
import Swal from "sweetalert2";
import { create } from "services/announcements";

const AddAnuncios = ({ idSchool, handleChangeView }) => {
  const [title, setTitle] = useState("");
  const [announcement, setAnnouncement] = useState("");
  const agregarAnuncio = async (e) => {
    e.preventDefault();
    const request = await create({
      d: 2,
      id_col: idSchool,
      titulo: title,
      anuncio: announcement,
      imagen: "/dadasda/imagen",
    });

    if (request.data) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Grabado!",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeView(0);
        }
      });
    }
  };

  const handleTitleInput = (e) => {
    setTitle(e.target.value);
  };

  const handleAnnouncementInput = (e) => {
    setAnnouncement(e.target.value);
  };

  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <form
        onSubmit={agregarAnuncio}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px 0px",
          width: "50%",
        }}
      >
        <h2 className="text-center text-primary p-2">Agregar anuncios</h2>
        <input
          id="titulo"
          type="text"
          className="form-control m-2 p-2"
          placeholder="Titulo"
          required
          onChange={handleTitleInput}
        />
        <textarea
          id="anuncio"
          className="form-control m-2 p-2"
          placeholder="Ej: Mañana se celebrará la entrega de boletines"
          required
          onChange={handleAnnouncementInput}
        ></textarea>
        <button className="btn btn-info">Agregar Anuncio</button>
        <svg onClick={() => {
                            handleChangeView(0);
                        }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            fill="currentColor"
                            className="mt-2"
                            style={{ cursor: 'pointer' }}
                            viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
      </form>
    </div>
  );
};

export default AddAnuncios;
