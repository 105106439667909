import React from 'react'

function mision() {
    return (
        <div>
            <h2 className="text-center font-weigth-bolder">Misión</h2>
            <div className="w-75 m-auto">
                <p>Tenemos la meta de poder ofrecer a Colombia el asesoramiento a sus instituciones, la llegada de la nueva era con lo último en tecnología, mejorando la calidad de educación en cada rincón de nuestro país.</p>

            </div>
        </div>
    )
}

export default mision
