import React from 'react';

const AgregarEventoDocente = ({volver}) => {
    return (
        <div>
            <div className="container">
                <form action="" className="caja-form shadow-sm">
                    <div className="row">
                        <svg onClick={() => {
                            volver(0);
                        }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            fill="currentColor"
                            style={{ cursor: 'pointer' }}
                            viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
                        <div className="col-md-12 text-center">
                            <h3>Evento Docentes</h3>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "5px", boxSizing: "border-box" }}>
                        <div className="col-md-6">
                            <label htmlFor="Nombre" className="form-label">Nombre evento:</label>
                            <input className="form-control" type="text" id="Nombre" name="Nombre" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="fecha" className="form-label">Fecha:</label>
                            <input className="form-control" type="datetime-local" id="fecha" name="fecha" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="detalle">Descripción</label>
                            <textarea id="detalle" name="detalle" className="form-control" style={{ width: "100%", boxSizing: "border-box" }} rows="10"></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center mt-3">
                            <button type="submit" className="btn btn-primary">Crear</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AgregarEventoDocente;