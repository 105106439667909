/**
 * ==================================================
 * Sistema de planeación de recursos empresariales 
 * @author Enso-Learning
 * @copyright Copyright (c) 2022, Enso-Learning
 * @version 1.0 EDU_PLT
 * ==================================================
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import URL from "../../../URL";
import Swal from 'sweetalert2';
const CrearColegio = () => {
    const [estado, setestado] = useState(0)
    const [colegios, setcolegios] = useState([])
    const [Cselecionado, setCselecionado] = useState(0)
    const [subvista, setsubvista] = useState(0)
    const [cursos, setcursos] = useState([])
    const [CursoSeleccionado, setCursoSeleccionado] = useState(0)
    const [Estudiantes, setEstudiantes] = useState([])
    const [form, setform] = useState([])
    const User = useSelector((state) => state.user);



    const GuardarDatos = (e) => {
        if (e.target.name === "Colegio") {
            setCselecionado(e.target.value.trim())
        }
        setform({
            ...form,
            [e.target.name]: e.target.value.trim()
        })
        if (e.target.name === 'cargue') {
            setform({
                ...form,
                [e.target.name]: e.target.files[0]
            })
        }
    }
    const seleccionarColegio = (e, vista) => {
        setCselecionado(e);
        setsubvista(vista)
    }
    const seleccionarCurso = (e, vista) => {
        setCursoSeleccionado(e)
        setsubvista(vista)
    }
    const volver = () => {
        let e = subvista;
        e--
        setsubvista(e)
    }
    const Envio_Masivo = async (e) => {
        e.preventDefault();
        mostrarLoader();
        let archivo = "";
        try {
            if (form.cargue) {
                const formDatos = new FormData();
                formDatos.append('archivo', form.cargue)
                const consulta = await axios.post(`${URL.servidor}/api-php-react/Subir_estudiantes_masivo.php`, formDatos, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }

                })
                archivo = consulta;
                if (archivo.data.status === "success" && !archivo.data.error) {
                    let idCurso = JSON.stringify({ d: 0, url: archivo.data.url, colegio:Cselecionado})
                    const api = axios.create({ baseURL: URL.servidor });
                    const response = await api.post('/api-php-react/admin/Crear_Estudiante_masivo.php', idCurso);
                    const data = response.data;

                    if (data.estado) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Correcto',
                            text: 'Subido correctamente'
                        })
                    }else{
                        Swal.fire({
                            icon: 'warning',
                            title: 'Alerta',
                            text: data.Mensaje
                        })
                    }
                } else {
                    throw new Error(archivo.data.menssage);
    
                }
            }else{
                throw new Error("Error: No a adjuntado el archivo de cargue");

            }
        } catch (error) {
            console.error("Error:", error.message);
            Swal.fire({
                icon: 'warning',
                title: '¡Alerta!',
                text: error.message,
            })
        }
    }
    function mostrarLoader() {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }
    function ocultarLoader() {
        Swal.close();
    }
    const Enviar = async (e) => {
        e.preventDefault();
        var Datos = {
            d: 6,
            Nombres: form.Nombre,
            Apellido: form.Apellidos,
            Curso: form.Curso,
            Id_Col: form.Colegio,
            Email: form.Correo,
            Ciclo: form.Ciclo,
            Codigo: "reseller"
        }
        const consulta = await axios({
            method: "post",
            url: `${URL.servidor}/api-php-react/admin/Crud_admin.php`,
            data: Datos
        })
        if (consulta.data.estado === false) {
            Swal.fire({
                icon: 'warning',
                title: '¡Alerta!',
                text: consulta.data.Mensaje,
            })

        } else if (consulta.data) {
            Swal.fire({
                icon: 'success',
                title: '¡Exitoso!',
                text: 'Estudiante creado con éxito',
                confirmButtonText: 'Entendido'
            }).then((result) => {
                if (result.isConfirmed) {
                    setestado(1)
                }
            })
        }
    }
    useEffect(() => {
        const TrearDatos = async () => {
            if (User.id) {
                let idCurso = JSON.stringify({ d: 2, reseller: User.id });
                const api = axios.create({ baseURL: URL.servidor });
                const response = await api.post(
                    "/api-php-react/admin/Crud_admin.php",
                    idCurso
                );
                const data = response.data;
                setcolegios(data);
            }

        }
        TrearDatos();
    }, [estado, User]);
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = JSON.stringify({ d: 1, id: Cselecionado });
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post(
                "/api-php-react/info_Cursos.php",
                idCurso
            );
            const data = response.data;
            setcursos(data);
        }
        TrearDatos();
    }, [Cselecionado]);
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = JSON.stringify({ d: 5, curso: CursoSeleccionado });
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post(
                "/api-php-react/admin/Crud_admin.php",
                idCurso
            );
            const data = response.data;
            setEstudiantes(data);
        }
        TrearDatos();
    }, [CursoSeleccionado]);
    return (
        <div className="col-md-12">
            <div className="row center">
                <div className="col-md-4">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(0)}>Crear Estudiante</p>
                </div>
                <div className="col-md-4">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(1)}>Consultar Estudiantes</p>
                </div>
                <div className="col-md-4">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(2)}>Cargue masivo</p>
                </div>
            </div>
            {estado === 0 ?
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={Enviar}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Nombres:</label>
                                <input type="text" name='Nombre' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={GuardarDatos} required />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Apellidos: </label>
                                        <input type="text" onChange={GuardarDatos} name='Apellidos' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Colegio:</label>
                                    <select className="form-control" onChange={GuardarDatos} name="Colegio" required>
                                        <option value="">Seleccione</option>
                                        {colegios.length > 0 ?
                                            colegios.map(Colegio =>
                                                Colegio.Cordinador ?
                                                    <option value={Colegio.id}>{Colegio.nombreC}</option>
                                                    :
                                                    null
                                            )
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Curso:</label>
                                    <select className="form-control" onChange={GuardarDatos} name="Curso" required>
                                        <option value="">Seleccione</option>
                                        {cursos.length > 0 && cursos[0].id ?
                                            cursos.map(curso =>
                                                <option value={curso.id}>{curso.Curso_Nu}</option>
                                            )
                                            :
                                            <option value="">No a seleccionado un colegio</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Correo: </label>
                                        <input type="email" onChange={GuardarDatos} name='Correo' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Ciclo: </label>
                                        <select className="form-control" onChange={GuardarDatos} name="Ciclo" required>
                                            <option value="">Seleccione</option>
                                            <option value="5">1</option>
                                            <option value="6">2</option>
                                            <option value="7">3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Guarda</button>
                        </form>
                    </div>
                </div>
                :
                null
            }
            {estado === 1 ?
                <div className="row center">
                    <div className='col-md-12'>
                        {subvista !== 0 ?
                            <span className='pointer' onClick={() => volver()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </span>
                            :
                            null
                        }
                    </div>
                    {subvista === 0 && colegios.length > 0 ?
                        colegios.map((colegio) =>
                            <div className="col-md-6">
                                <div className="card">
                                    <img src="..." className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{colegio.nombreC}</h5>
                                        <h6 className="card-title">{colegio.nombreC}</h6>
                                        <h6 className="card-title">{colegio.Cupos_max}/{colegio.cupos}</h6>
                                        <p className="card-text">{colegio.info}</p>
                                        <button type="button" className="btn btn-primary" onClick={() => seleccionarColegio(colegio.id, 1)}>Ver Colegio</button>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        null
                    }
                    {subvista === 1 && cursos.length > 0 ?
                        cursos.map((curso) =>
                            <div className="col-md-4">
                                <div className="card">
                                    <img src="..." className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{curso.Curso_Nu}</h5>
                                        <button type="button" className="btn btn-primary" onClick={() => seleccionarCurso(curso.id, 2)}>Ver Estudiantes</button>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        null
                    }
                    {subvista === 2 && cursos.length > 0 ?
                        <table className="table">
                            <thead className="table-dark">
                                <tr>
                                    <th>#</th>
                                    <td>Nombres</td>
                                    <td>Apellidos</td>
                                    <td>fecha registro</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Estudiantes.map((estudiante) =>
                                    <tr>
                                        <th scope="row">{estudiante.id}</th>
                                        <td>{estudiante.Nombre}</td>
                                        <td>{estudiante.Apellido}</td>
                                        <td>{estudiante.fecha_reg}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                        :
                        null
                    }
                </div>
                :
                null
            }
            {estado === 2 ?
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={Envio_Masivo} className='text-center'>
                            <div className="col-md-12">
                                <label htmlFor="exampleInputEmail1" className="form-label">Colegio:</label>
                                <select className="form-control" onChange={GuardarDatos} name="Colegio" required>
                                    <option value="">Seleccione</option>
                                    {colegios.length > 0 ?
                                        colegios.map(Colegio =>
                                            Colegio.Cordinador ?
                                                <option value={Colegio.id}>{Colegio.nombreC}</option>
                                                :
                                                null
                                        )
                                        :
                                        null
                                    }
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="cargue" className="form-label">Nombres:</label>
                                <input type="file" name='cargue' className="form-control" id="cargue" onChange={GuardarDatos} required />
                            </div>
                            <button type="submit" className="btn btn-primary">Cargar Datos</button>
                        </form>
                    </div>
                </div>
                :
                null
            }

        </div>
    )
}
export default CrearColegio;