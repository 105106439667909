import React from 'react';

const Estudiantetwopage = () => {
    return (
        <div>
            
        </div>
    );
}
 
export default Estudiantetwopage;