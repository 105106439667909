import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import URL from "URL";
import Swal from "sweetalert2";
import AddAnuncios from "./addAnuncios";
import EditAnuncios from "./editAnuncios";
import { getAll, remove } from "services/announcements";

const AdminAnuncios = ({ volver }) => {
  const { id_Col } = useSelector((state) => state.user);
  const [announcements, setAnnouncements] = useState([]);
  const [interfaceNumber, setInterfaceNumber] = useState(0);
  const [announcementSingle, setAnnouncementSingle] = useState({});

  useEffect(() => {
    getAll({ d: 3, id_col: id_Col }).then((res) => {
      setAnnouncements(res.data);
    });
  }, [id_Col, interfaceNumber]);

  const removeAnnouncement = (announcement) => {
    Swal.fire({
      title: "¿Quieres eliminar este anuncio?",
      showDenyButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const request = await remove({
          d: 1,
          id: announcement.id,
        });
        if (request) {
          const newAnnouncenment = announcements.filter(
            (ads) => ads.id !== announcement.id
          );
          setAnnouncements(newAnnouncenment);
        }
      }
    });
  };

  const editAnnouncement = (announcement) => {
    setInterfaceNumber(2);
    setAnnouncementSingle(announcement);
  };

  return (
    <div>
      {interfaceNumber === 0 ? (
        <div
          className="p-3"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {announcements.length !== 0 ? (
            announcements.map((announcement) => (
              <div
                className="p-2 m-2 shadow row bg-gris-whi border-30 rounded"
                style={{ width: "50%" }}
                key={announcement.id}
              >
                <div className="col-sm-10">
                  <h5 className="mt-3">
                    <strong> {announcement.titulo} </strong>
                  </h5>
                  <h6> {announcement.anuncio} </h6>
                  <p className="text-secondary"> {announcement.fecha} </p>
                </div>
                <div className="col-sm-2">
                  <div className="d-flex justify-content-center">
                    <div
                      className="pointer Areas bg-primary d-flex justify-content-center p-3 m-2 shadow rounded-circle"
                      onClick={() => removeAnnouncement(announcement)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-trash text-white"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </div>
                    <div
                      className="pointer Areas bg-primary  d-flex justify-content-center p-3 m-2 shadow rounded-circle"
                      onClick={() => editAnnouncement(announcement)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-pencil text-white"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>No hay anuncios en este momento</h1>
          )}
          <div className="d-flex justify-content-center p-5">
            <div className="d-flex justify-content-center mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" title="holaaa" fill="currentColor" class="bi bi-calendar-plus m-2" style={{ cursor: 'pointer' }} viewBox="0 0 16 16" onClick={() => setInterfaceNumber(1)}>
                <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7" />
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>

              <svg onClick={() => {
                volver(0);
              }}
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="m-2"
                style={{ cursor: 'pointer' }}
                viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
              </svg>
            </div>
          </div>
        </div>
      ) : null}

      {interfaceNumber !== 0 ? (
        <div>
          <div className="d-flex justify-content-start mt-2">
          </div>
          {interfaceNumber === 1 ? (
            <AddAnuncios
              idSchool={id_Col}
              handleChangeView={setInterfaceNumber}
            />
          ) : null}
          {interfaceNumber === 2 ? (
            <EditAnuncios
              announcementSingle={announcementSingle}
              handleChangeView={setInterfaceNumber}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default AdminAnuncios;
