import React from 'react';
import CalificarStrellas from "components/libros/CalificarStrellas"
const Modal = ({ show, handleClose, libro }) => {
    return (
        <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">Calificación</h5>
                    </div>
                    <div className="modal-body">
                        <CalificarStrellas libro = {libro}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;