import React from 'react';

const Planes = () => {
    return (
        <div>
            <h2>Planes </h2>
        </div>
    );
}
 
export default Planes;