/**
 * ==================================================
 * Sistema de planeación de recursos empresariales 
 * @author Enso-Learning
 * @copyright Copyright (c) 2022, Enso-Learning
 * @version 1.0 EDU_PLT
 * ==================================================
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import URL from "../../../URL";
import Swal from 'sweetalert2';
const CrearColegio = () => {
    const [estado, setestado] = useState(0)
    const [colegios, setcolegios] = useState([])
    const [Cselecionado, setCselecionado] = useState(0)
    const [subvista, setsubvista] = useState(0)
    const [form, setform] = useState([])
    const [Materias, setMaterias] = useState([])
    const [Docentes, setDocentes] = useState([])
    const User = useSelector((state) => state.user);



    const GuardarDatos = (e) => {
        if (e.target.name === "Colegio") {
            setCselecionado(e.target.value.trim())
        }
        setform({
            ...form,
            [e.target.name]: e.target.value.trim()
        })
    }
    const seleccionarColegio = (e, vista) => {
        setCselecionado(e);
        setsubvista(vista)
    }
    const volver = () => {
        let e = subvista;
        e--
        setsubvista(e)
    }
    const Enviar = async (e) => {
        e.preventDefault();
        var Datos = {
            "d":8,
            Email:form.Correo,
            Nombres:form.Nombre,
            Apellidos:form.Apellidos,
            CC:form.cc,
            Colegio:form.Colegio,
            Materia:form.Materia
        }
        const consulta = await axios({
            method: "post",
            url: `${URL.servidor}/api-php-react/admin/Crud_admin.php`,
            data: Datos
        })
        if (!consulta.data.estado) {
            Swal.fire({
                icon: 'warning',
                title: '¡Alerta!',
                text: consulta.data.Mensaje,
            })

        } else if(consulta.data.estado) {
            Swal.fire({
                icon: 'success',
                title: '¡Exitoso!',
                text: 'Docente creado con exito',
                confirmButtonText: 'Entendido'
            }).then((result) => {
                if (result.isConfirmed) {
                    setestado(1)
                }
            })
        }
    }
    useEffect(() => {
        const TrearDatos = async () => {
            if (User.id) {
                let idCurso = JSON.stringify({ d: 2, reseller: User.id });
                const api = axios.create({ baseURL: URL.servidor });
                const response = await api.post(
                    "/api-php-react/admin/Crud_admin.php",
                    idCurso
                );
                const data = response.data;
                setcolegios(data);
            }

        }
        TrearDatos();
    }, [estado, User]);
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = JSON.stringify({ d: 9, colegio: Cselecionado });
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post(
                "/api-php-react/admin/Crud_admin.php",
                idCurso
            );
            const data = response.data;
            setDocentes(data);
        }
        TrearDatos();
    }, [Cselecionado,subvista,estado]);
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = JSON.stringify({ d: 13 });
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post(
                "/api-php-react/info_admin.php",
                idCurso
            );
            const data = response.data;
            setMaterias(data);
        }
        TrearDatos();
    }, []);
    return (
        <div className="col-md-12">
            <div className="row center">
                <div className="col-md-6">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(0)}>Crear Docente</p>
                </div>
                <div className="col-md-6">
                    <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setestado(1)}>Consultar Docente</p>
                </div>
            </div>
            {estado === 0 ?
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={Enviar}>
                            <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label">Nombres:</label>
                                <input type="text" name='Nombre' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={GuardarDatos} required />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Apellidos: </label>
                                        <input type="text" onChange={GuardarDatos} name='Apellidos' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Correo: </label>
                                        <input type="email" onChange={GuardarDatos} name='Correo' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Documento identidad: </label>
                                        <input type="number" onChange={GuardarDatos} name='cc' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label for="exampleInputEmail1" className="form-label">Colegio:</label>
                                    <select className="form-control" onChange={GuardarDatos} name="Colegio" required>
                                        <option value="">Seleccione</option>
                                        {colegios.length > 0 ?
                                            colegios.map(Colegio =>
                                                Colegio.Cordinador ?
                                                    <option value={Colegio.id}>{Colegio.nombreC}</option>
                                                    :
                                                    null
                                            )
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1" className="form-label">Materia: </label>
                                        <select className="form-control" onChange={GuardarDatos} name="Materia" required>
                                            <option value="">Seleccione</option>
                                            {Materias.map(materia =>
                                                <option value={materia.id}>{materia.N_Materia}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
                :
                null
            }
            {estado === 1 ?
                <div className="row center">
                    <div className='col-md-12'>
                        {subvista !== 0 ?
                            <span className='pointer' onClick={() => volver()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </span>
                            :
                            null
                        }
                    </div>
                    {subvista === 0 && colegios.length > 0 ?
                        colegios.map((colegio) =>
                            <div className="col-md-6">
                                <div class="card">
                                    <img src="..." class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <h5 class="card-title">{colegio.nombreC}</h5>
                                        <h6 class="card-title">{colegio.nombreC}</h6>
                                        <h6 class="card-title">{colegio.Cupos_max}/{colegio.cupos}</h6>
                                        <p class="card-text">{colegio.info}</p>
                                        <button type="button" class="btn btn-primary" onClick={() => seleccionarColegio(colegio.id, 1)}>Ver Colegio</button>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        null
                    }
                    {subvista === 1 && Docentes.length > 0 ?
                        <table class="table">
                            <thead class="table-dark">
                                <tr>
                                    <th>#</th>
                                    <td>Nombres</td>
                                    <td>Apellidos</td>
                                    <td>Correo</td>
                                    <td>aula</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Docentes.map((Docente) =>
                                    <tr>
                                        <th scope="row">{Docente.id}</th>
                                        <td>{Docente.Nombre}</td>
                                        <td>{Docente.apellido}</td>
                                        <td>{Docente.Correo}</td>
                                        <td>
                                            {Docente.id_aula ?
                                                <span style={{ color: 'green' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                    </svg>
                                                </span>
                                                :
                                                <span style={{ color: 'red' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </span>
                                            }

                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </div>
    )
}
export default CrearColegio;