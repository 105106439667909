import React from 'react';

const EstudianteThreePage = () => {
    return (
        <div>
            
        </div>
    );
}
 
export default EstudianteThreePage;