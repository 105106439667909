import URL from "URL";
import axios from "axios";

const Consulta = async (DatosConsulta , Ruta_Archivo) =>   {
    const DatosJson = JSON.stringify(DatosConsulta);
    const api = axios.create({ baseURL: URL.servidor });
    const response = await api.post("/api-php-react/"+Ruta_Archivo, DatosJson);
    return response;
}

export default Consulta