import React from 'react'

function educativo() {
    return (
        <div>
            <h2 className="text-center font-weigth-bolder">Educativo</h2>
            <div className="w-75 m-auto">
                <p>Guiados por el marco europeo Creamos contenido educativo de calidad, cumpliendo los términos del mismo, creamos nuestras actividades y evaluaciones.</p>
                <p>Queremos mejorar los resultados de las pruebas de estado ICFES para ello diseñamos un plan completo de medición y mejoramiento personalizado para cada estudiante.</p>

            </div>
        </div>
    )
}

export default educativo
