import { useState, useEffect } from "react";
import * as ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Calendario from "components/calendario.js";
import EditModal from "./components/EditSchool";
import { getById } from "services/school";
import { GetAll } from "services/agenda_intituto";
import { getAll } from "services/announcements";
const AdminSchool = () => {
  //lugar donde obtengo el id del colegio
  const currCoordinator = useSelector((state) => state.user);
  //Estados para el fetching de datos del colegio
  const [currSchool, setCurrSchool] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [eventos, seteventos] = useState();
  const [circulares, setcirculares] = useState()

  //Estado para editar el colegio
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {

    getById({ d: 0, id: currCoordinator.id_Col })
      .then((res) => {
        setCurrSchool(res.data);
        setIsLoading(false);
        setIsFetching(false);
      })
    GetAll({ id: currCoordinator.id_Col }).then((res) => {
      seteventos(res.data);
    })
    getAll({ d: 3, id_col: currCoordinator.id_Col }).then((res) => {
      setcirculares(res.data);
    });

  }, [currCoordinator]);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="cont-princi-docentes">
      {isOpen &&
        ReactDOM.createPortal(
          <EditModal
            onOpenModal={handleOpenModal}
            school={currSchool}
            handleFetcher={setIsFetching}
          />,
          document.getElementById("modal-container-render")
        )}
      <div className="cont-imagen-docentes1">
        <div className="titulo-Vista">
          Bienvenido coordinador o administrativo del
        </div>
        <div className="view-title-coordinator">
          {!isLoading && currSchool ? currSchool.nombreC : <h3>Cargando...</h3>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
            onClick={handleOpenModal}
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
        </div>
      </div>

      <div>
        <div className="cont-info-home1 container-event">
          <div>
            <h4>Agenda</h4>
            <div className="cont-agenda-home">
              {eventos && eventos.length > 0 ? eventos.map((evento, key) => (
                <div key={key}>
                  <p>{evento.nombre}</p>
                  <p>{evento.fecha}</p>
                </div>
              )) :
                <div>
                  No hay eventos en la agenda!
                </div>
              }
              <div className="d-flex justify-content-end ver-mas-agenda">{`Ver mas >`}</div>
            </div>
          </div>

          <div>
            <Calendario
              contenedor={`cont-calendario-home`}
              diasCale={`dias-calendario-home`}
              colorLetra={"mes-calendario-contenido"}
            />
          </div>

          <div className="container-event">
            <div className="backdrop-home backdrop-event">proximamente...</div>
            <h4>Proximos eventos intitucionales</h4>
            <ul>
              <li>Dia del idioma</li>
              <li>Dia de la tierra</li>
              <li>Semana de la ciencia</li>
              <li>Integracion halloween</li>
            </ul>
          </div>
          <div className="cont-circulares-home container-event">
            <h4>Criculares</h4>
            {circulares ?
              circulares.map((circular, key) => (
                <div key={key}>
                  <p>
                    <strong>Circular {++key} de {circular.fecha}</strong> <br />
                    {circular.anuncio}
                  </p>
                </div>
              ))
              :
              <div>
                No hay circulares para este año!
              </div>

            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminSchool;
