import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import VerLibros from "./../../components/libros/VerLibros";
import CryptoJS from "crypto-js";

const VerLibro = () => {
  const [Datos, setDatos] = useState({});
  const [VerLibro, setVerLibro] = useState({ carpeta: "", libro: "" });
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const decrypt = (data) => {
      try {
        const bytes = CryptoJS.AES.decrypt(data, 'B');
        return bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error("Desencriptación fallida:", error);
        return null;
      }
    };

    const card = query.get("card");
    const token = query.get("token");

    if (card && token) {
      const decryptedCard = decrypt(card.replace(/ /g, '+'));
      const decryptedToken = decrypt(token.replace(/ /g, '+'));
      console.log("🚀 ~ useEffect ~ card:", decryptedCard);
      console.log("🚀 ~ useEffect ~ token:", decryptedToken);

      if (decryptedCard && decryptedToken) {
        setVerLibro({ carpeta: decryptedCard, libro: decryptedToken });
      } else {
        window.location.replace("/");
      }
    } else {
      window.location.replace("/");
    }
  }, [location.search]);

  const Escribir = (e) => {
    setDatos({
      ...Datos,
      [e.target.name]: e.target.value.trim()
    });
  };

  return (
    <div className="container mt-7 mb-7 shadow-lg h-25">
      {VerLibro.carpeta && VerLibro.libro ? (
        <VerLibros VerLibro={VerLibro} />
      ) : (
        <div>Error al cargar el libro.</div>
      )}
    </div>
  );
};

export default VerLibro;
