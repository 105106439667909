import React, { useState, useEffect } from "react";
import axios from "axios";
import URL from "../../../URL.js";
import Swal from "sweetalert2";
import Buscador from "components/Buscadores/Buscador.jsx";
import CamposSave from "components/CamposSave.js";
import BuscadorLibros from "components/Buscadores/BuscadorLibros.jsx";
import VerLibros from "./../../../components/libros/VerLibros.jsx";
import Editarlibro from "./../../../components/libros/EditarLibros.jsx";

const Libros = () => {
  const [CamposTemas, setCamposTemas] = useState({});
  const [DatosRecibidos, setDatosRecibidos] = useState([]);
  const [DatosRecibidosDos, setDatosRecibidosDos] = useState([]);
  const [LibrosCargados, setLibrosCargados] = useState([]);
  const [Cargar, setCargar] = useState(false);
  const [Estado, setEstado] = useState(0)
  const [LibroBusqueda, setLibroBusqueda] = useState({})
  const [VerLibro, setVerLibro] = useState({})

  useEffect(() => {
    const sendData = async () => {
      let DatosJson = JSON.stringify("1");
      const api = axios.create({ baseURL: URL.servidor });
      const response = await api.post("/api-php-react/c_genero.php", DatosJson);
      let data = response.data;

      setDatosRecibidos(...DatosRecibidos, data);
      let DatoJsonA = JSON.stringify("1");
      const apiA = axios.create({ baseURL: URL.servidor });
      const responseA = await apiA.post(
        "/api-php-react/c_autor.php",
        DatoJsonA
      );
      let dataA = responseA.data;

      setDatosRecibidosDos(...DatosRecibidosDos, dataA);

      const apiB = axios.create({ baseURL: URL.servidor });
      let Consulta = { d: 0 }
      const responseB = await apiB.post(
        "/api-php-react/admin/info_libros.php", Consulta);
      let dataB = responseB.data;

      setLibrosCargados(dataB.datos);
    };
    sendData();
    //eslint-disable-next-line
  }, []);
  const NoRecargar = async (e) => {
    e.preventDefault();
    let Archivo = document.getElementById("IMG").files[0];
    if (Archivo && Archivo.type &&
      (Archivo.type === "image/jpeg" ||
        Archivo.type === "image/jpg" ||
        Archivo.type === "image/webp" ||
        Archivo.type === "image/svg" ||
        Archivo.type === "image/png")
    ) {
      setCargar(true);
      const formData = new FormData();
      formData.append("archivo", Archivo);
      let res = await axios.post(
        `${URL.servidor}/api-php-react/Subir_portadas.php`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      let data = res.data;
      if (data.status === "error") {
        Swal.fire({
          title: "Error",
          text: data.error,
          icon: "error",
        });
      } else if (data.status === "success") {
        let img = data.url;
        let datosEnviar = {
          Nombre: CamposTemas.Nombre,
          objetivo: CamposTemas.objetivo,
          puntos: CamposTemas.Puntos,
          autor: CamposTemas.Autor,
          genero: CamposTemas.Genero,
          intro: CamposTemas.intro,
          publico: CamposTemas.publico,
          rese: CamposTemas.reseña,
          imagen: img,
          pdf: CamposTemas.libro,
          carpeta: CamposTemas.carpeta,
          d: 0,
          editorial: CamposTemas.Editorial,
        };
        let DatosJson = JSON.stringify(datosEnviar);
        const consulta = await axios({
          method: "post",
          url: `${URL.servidor}/api-php-react/info_libros.php`,
          data: DatosJson,
        });
        let datosRecibidos = consulta.data;

        if (datosRecibidos === true) {
          Swal.fire({
            icon: "success",
            title: "Se ha guardado correctamente",
          });
          setCargar(false);
          window.location.replace("/AdminLibros");
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puedes agregar imagenes en este campo.",
      });
      setCargar(false);
    }
  };
  return (
    <div>
      <div className="row center">
        <div className="col-md-6">
          <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setEstado(0)}>Crear libro</p>
        </div>
        <div className="col-md-6">
          <p className="card-text text-center shadow p-3 m-2 Areas pointer" onClick={() => setEstado(1)}>buscar libro</p>
        </div>
      </div>
      {Estado === 0 ? 
            <div className="m-2 shadow p-3">
            <h5 className="m-2">
              {" "}
              Aquí podrás agregar libros a la plataforma, para luego agregarlos en
              diferentes actividades.{" "}
            </h5>
            <form onSubmit={NoRecargar} encType="multipart/form-data" action="">
              <input
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="Nombre"
                placeholder="Nombre"
                className="form-control m-2"
              />
              <input
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="Editorial"
                placeholder="Editorial"
                className="form-control m-2"
              />
              <select
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="publico"
                className="form-control m-2"
              >
                <option value={null}>Público </option>
                <option value="1"> Ciclo Uno </option>
                <option value="2"> Ciclo Dos </option>
                <option value="3"> Ciclo Tres </option>
                <option value="4"> Para todos </option>
              </select>
    
              <select
                name="Autor"
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                className="form-control m-2"
              >
                <option value={null}> Autor </option>
                {DatosRecibidosDos.map((Autor) => (
                  <option key={Autor.id} value={Autor.id}>
                    {" "}
                    {Autor.autor}{" "}
                  </option>
                ))}
              </select>
    
              <select
                name="Genero"
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                className="form-control m-2"
              >
                <option value={null}> Género </option>
                {DatosRecibidos && DatosRecibidos.length > 0 ? (DatosRecibidos.map((genero) => (
                  <option key={genero.id} value={genero.id}>
                    {" "}
                    {genero.genero}{" "}
                  </option>
                ))) : null}
              </select>
    
              <textarea
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="intro"
                placeholder="Introducción del libro"
                className="form-control m-2"
              ></textarea>
              <textarea
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="objetivo"
                placeholder="Objetivo"
                className="form-control m-2"
              ></textarea>
              <textarea
                onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                name="reseña"
                placeholder="Reseña"
                className="form-control m-2"
              ></textarea>
              <h6 className="m-2 text-white"> Portada del libro </h6>
              <div className="m-2">
                <input
                  onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
                  name="portada"
                  type="file"
                  id="IMG"
                  accept=".jpg, .jpeg, .png, .webp, .svg"
                />
              </div>
    
              <h6 className="m-2 text-white"> Carpeta del libro</h6>
              <div className="m-2">
                <select className="form-control m-2" name="carpeta" onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}>
                  <option value={0}>Selecione una carpeta</option>
                  {LibrosCargados && LibrosCargados.length > 0 ?
                    (LibrosCargados.map((libro, key) => (
                      <option key={key} value={libro}>
                        {libro}
                      </option>
                    ))) :
                    null
                  }
                </select>
              </div>
              <h6 className="m-2 text-white"> Carpeta del libro </h6>
              <div className="m-2">
                <Buscador categorio={CamposTemas.carpeta} state={CamposTemas} setstate={setCamposTemas} />
              </div>
    
              <button className="btn btn-primary m-2"> Enviar</button>
            </form>
            {Cargar ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-warning" role="status">
                  <span className="sr-only h1">Loading...</span>
                </div>
              </div>
            ) : null}
          </div>
      :
        null
      }
      {Estado === 1 ? 
        <BuscadorLibros LibroBusqueda={LibroBusqueda} setLibroBusqueda={setLibroBusqueda} setEstado={setEstado} setVerLibro={setVerLibro} />
      :
        null
      }
      {Estado === 2 ?
        <VerLibros VerLibro={VerLibro} />
      :
      null
      } 
      {Estado === 3 ?
        <Editarlibro LibroBusqueda={LibroBusqueda} setEstado={setEstado} />
      :
      null
      } 
    </div>
  );
};

export default Libros;
