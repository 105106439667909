import React from 'react';
import DashBoard from '../Admin-page/components/dashboard';

const NavbarAdmin = () => {
    return (  
        <div> 
            <DashBoard />
        </div>
    );
}
 
export default NavbarAdmin;