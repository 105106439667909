import React, { useState, useEffect } from "react";
import axios from "axios";
import URL from "../../URL.js";
import Swal from "sweetalert2";
import CamposSave from "components/CamposSave.js";


const Libros = ({LibroBusqueda, setEstado}) => {
  
    const [CamposTemas, setCamposTemas] = useState({});
    const [DatosRecibidos, setDatosRecibidos] = useState([]);
    const [DatosRecibidosDos, setDatosRecibidosDos] = useState([]);
    const [Cargar, setCargar] = useState(false);
  
    useEffect(() => {
        const sendData = async () => {
          try {
            const [respuesta, respuestaA] = await Promise.all([
              axios.post(`${URL.servidor}/api-php-react/c_genero.php`, JSON.stringify("1")),
              axios.post(`${URL.servidor}/api-php-react/c_autor.php`, JSON.stringify("1"))
            ]);
      
            const datos = respuesta.data;
            const datosA = respuestaA.data;
      
            // Asegúrate de no duplicar los datos
            setDatosRecibidos(datos);
            setDatosRecibidosDos(datosA);
      
            // Actualiza los campos de temas con VerLibro
            setCamposTemas(LibroBusqueda.libro ); // Suponiendo que VerLibro está definido en otro lugar
          } catch (error) {
            console.error("Error:", error);
          }
        };
      
        sendData();
      }, [LibroBusqueda]); // Solo ejecuta el efecto cuando cambia VerLibro
    const NoRecargar = async (e) => {
        e.preventDefault();
        let Archivo = document.getElementById("IMG").files[0];
        if(Archivo){
          console.log("entra con img")
          if (Archivo && Archivo.type &&
            (Archivo.type === "image/jpeg" ||
              Archivo.type === "image/jpg" ||
              Archivo.type === "image/webp" ||
              Archivo.type === "image/svg" ||
              Archivo.type === "image/png")
          ) {
            setCargar(true);
            const formData = new FormData();
            formData.append("archivo", Archivo);
            let res = await axios.post(
              `${URL.servidor}/api-php-react/Subir_portadas.php`,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );
            let data = res.data;
            if (data.status === "error") {
              Swal.fire({
                title: "Error",
                text: data.error,
                icon: "error",
              });
            } else if (data.status === "success") {
              let img = data.url;
              console.log(img);
              let datosEnviar = {
                Nombre: CamposTemas.Nombre,
                objetivo: CamposTemas.objetivo,
                puntos: CamposTemas.puntos,
                autor: CamposTemas.autor,
                genero: CamposTemas.genero,
                intro: CamposTemas.intro,
                publico: CamposTemas.publico,
                rese: CamposTemas.rese,
                imagen: img,
                d: 4,
                id: CamposTemas.id,
                editorial: CamposTemas.editorial,
              };
              let DatosJson = JSON.stringify(datosEnviar);
              const consulta = await axios({
                method: "post",
                url: `${URL.servidor}/api-php-react/admin/info_libros.php`,
                data: DatosJson,
              });
              let datosRecibidos = consulta.data;
              console.log("guardado bien ")
              console.log(datosRecibidos);
              if (datosRecibidos.datos === true) {
                console.log("guardado bien entra al mensaje")
                Swal.fire({
                  icon: "success",
                  title: "Se ha guardado correctamente",
                });
                setEstado(1);
              }
            }
          } else {
            Swal.fire({
              icon: "error",
              text: "Solo puedes agregar imagenes en este campo.",
            });
            setCargar(false);
          }
        }else{
              let datosEnviar = {
                Nombre: CamposTemas.Nombre,
                objetivo: CamposTemas.objetivo,
                puntos: CamposTemas.puntos,
                autor: CamposTemas.autor,
                genero: CamposTemas.genero,
                intro: CamposTemas.intro,
                publico: CamposTemas.publico,
                rese: CamposTemas.rese,
                imagen: CamposTemas.portada,
                pdf: CamposTemas.libro, 
                carpeta: CamposTemas.carpeta,
                d: 4,
                id: CamposTemas.id,
                editorial: CamposTemas.editorial,
              };
              let DatosJson = JSON.stringify(datosEnviar);
              const consulta = await axios({
                method: "post",
                url: `${URL.servidor}/api-php-react/admin/info_libros.php`,
                data: DatosJson,
              });
              let datosRecibidos = consulta.data;
      
              if (datosRecibidos.datos === true) {
                Swal.fire({
                  icon: "success",
                  title: "Se ha guardado correctamente",
                });
                setEstado(1);
              }
        };
      }
        
      

  return (
<div className="row">
    <div className="col-md-12">
        <form onSubmit={NoRecargar} encType="multipart/form-data" action="">
        <input
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="Nombre"
            placeholder="Nombre"
            className="form-control m-2"
            value={CamposTemas.Nombre}
        />
        <input
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="editorial"
            placeholder="Editorial"
            className="form-control m-2"
            value={CamposTemas.editorial}
        />
        <select
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="publico"
            className="form-control m-2"
            value={CamposTemas.publico}
        >
            <option value={null}>Público </option>
            <option value="1"> Ciclo Uno </option>
            <option value="2"> Ciclo Dos </option>
            <option value="3"> Ciclo Tres </option>
            <option value="4"> Para todos </option>
        </select>

        <select
            name="autor"
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            className="form-control m-2"
            value={CamposTemas.autor}
        >
            <option value={null}> Autor </option>
            {DatosRecibidosDos.map((Autor) => (
            <option key={Autor.id} value={Autor.id}>
                {" "}
                {Autor.autor}{" "}
            </option>
            ))}
        </select>

        <select
            name="Genero"
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            className="form-control m-2"
            value={CamposTemas.genero}
        >
            <option value={null}> Género </option>
            {DatosRecibidos && DatosRecibidos.length > 0 ? (DatosRecibidos.map((genero) => (
            <option key={genero.id} value={genero.id}>
                {" "}
                {genero.genero}{" "}
            </option>
            ))) : null}
        </select>

        <textarea
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="intro"
            placeholder="Introducción del libro"
            className="form-control m-2"
            value={CamposTemas.intro}
        ></textarea>
        <textarea
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="objetivo"
            placeholder="Objetivo"
            className="form-control m-2"
            value={CamposTemas.objetivo}
        ></textarea>
        <textarea
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="reseña"
            placeholder="Reseña"
            className="form-control m-2"
            value={CamposTemas.rese}
        ></textarea>
        <h6 className="m-2 text-white"> Portada del libro </h6>
        <div className="m-2">
            <input
            onChange={(e) => CamposSave(e, CamposTemas, setCamposTemas)}
            name="portada"
            type="file"
            id="IMG"
            accept=".jpg, .jpeg, .png, .webp, .svg"
            />
            <div className="row">
                <div className="col-md-3 text-center">
                    <img width="50%" src={CamposTemas.portada} alt={CamposTemas.Nombre} />
                </div>
            </div>
            
        </div>
        <div className="m-2 text-center">
            <button className="btn btn-primary">Guardar</button>
        </div>
        </form>
    </div>
</div>
  );
};

export default Libros;