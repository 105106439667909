import React from 'react';
import Navbar from '../Estudiante3-page/components/Navbar';

const NavbarCicloTres = () => {
    return (
        <div>
            <Navbar />
        </div>
    );
}
 
export default NavbarCicloTres;