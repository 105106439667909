import React from 'react';

const EstudianteOnePage = () => {
  return (

    <div>
      
    </div>
  );
}

export default EstudianteOnePage;