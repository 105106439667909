
import {useEffect, useState} from 'react';
import Consulta from 'utils/Consulta';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
const Modal = ({ show, handleClose, libro }) => {
    console.log("🚀 ~ Modal ~ libro:", libro)
    
    const [aulas, setaulas] = useState({})
    const [select, setselect] = useState(0)
    console.log("🚀 ~ Modal ~ select:", select)

    console.log("🚀 ~ Modal ~ aulas:", aulas)

    const docente = useSelector(state => state.docente)
    const Asignar = async () =>{
        let aul = JSON.parse(select);
        const data = {d:8, id_Pro: docente.id, Id_curso: aul.id_curso, id_Mat: aul.id_materia, id_libro: libro.element.id};
        const resultado = await Consulta (data,'info_docente.php');
        const datares = resultado.data;
        console.log("🚀 ~ Asignar ~ datares:", datares)
        if(datares.estado){
            Swal.fire({
                icon: "success",
                title: datares.mensaje,
              });
        }else{
            Swal.fire({
                icon: "warning",
                title: datares.mensaje,
              });
        }
    }
    useEffect(() => {
        const Asignar = async () =>{
            const data = {id: docente.id};
            const resultado = await Consulta (data, 'Cargar_Curaula.php');
            setaulas(resultado.data);
        }
        Asignar();

    }, [docente])
    
    return (
        <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">Asignar</h5>
                    </div>
                    <div className="modal-body">
                       <div className="row">
                            <div className="col-md-12">
                                <p>Seleccione el curso al cual desea agregar el libro, recuerde que solo puede tener 1 libro activo en cada curso</p>
                            </div>
                       </div>
                       <div className="row">
                            <div className="col-md-12 text-center">
                                <select className='form-control' onChange={(event) => setselect(event.target.value)} name="aula" id="aula">
                                    <option value="">Seleccione</option>
                                    { aulas.length > 0 ? 
                                        aulas.map((item, index)=>
                                            <option key={index} value={JSON.stringify(item)}>{item.Curso_Nu} - {item.N_Materia}</option>
                                        )
                                        :
                                        <h2>No Hay datos</h2>
                                    }
                                </select>
                                <button onClick={() => Asignar()} className='btn btn-primary m-2'>Asignar</button>
                            </div>
                       </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;