import React from 'react';
import Navbar from '../Estudiante2-page/components/navbar';

const NavbarCicloDos = () => {
    return (  
        <div>
            <Navbar />
        </div>
    );
}
 
export default NavbarCicloDos;