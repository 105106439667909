import React from 'react';
import '../css/bootstrap.css';
import '../css/index.css';
import GradosInterfaz from './createMallas/gradosInterfaz'


const Malla = () =>  {
  return (
    <div className="container" id="Malla" >
      <GradosInterfaz />
    </div>
  );
}

export default Malla;