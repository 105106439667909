import { useState } from "react";
import Swal from "sweetalert2";
import { edit } from "services/announcements";

const EditAnuncios = ({ announcementSingle, handleChangeView }) => {
  const [title, setTitle] = useState("");
  const [announcement, setAnnouncement] = useState("");
  const editarAnuncio = async (e) => {
    e.preventDefault();
    const consulta = await edit({
      d: 0,
      id: announcementSingle.id,
      titulo: title,
      anuncio: announcement,
    });
    if (consulta.data) {
      handleChangeView(0);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error en el servidor",
      });
    }
  };

  const handleTitleInput = (e) => {
    setTitle(e.target.value);
  };

  const handleAnnouncementInput = (e) => {
    setAnnouncement(e.target.value);
  };

  return (
    <div>
      <form onSubmit={editarAnuncio} className="w-75 m-auto">
        <h2 className="text-center text-primary p-2">Editar anuncio</h2>
        <p className="text-center text-white">{announcementSingle.titulo}</p>
        <input
          id="titulo"
          type="text"
          className="form-control m-2"
          required
          defaultValue={announcementSingle.titulo}
          onChange={handleTitleInput}
        />
        <textarea
          id="anuncio"
          className="form-control m-2"
          required
          defaultValue={announcementSingle.anuncio}
          onChange={handleAnnouncementInput}
        ></textarea>
        <div className="col text-center p-3">
          <button className="btn btn-info w-25"> Editar Anuncio </button><br></br>
          <svg onClick={() => {
            handleChangeView(0);
          }}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="currentColor"
            className="mt-2"
            style={{ cursor: 'pointer' }}
            viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </form>
    </div>
  );
};

export default EditAnuncios;
