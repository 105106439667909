import React, { useState } from 'react';
import Consulta from 'utils/Consulta';
import {useSelector} from "react-redux";
import Swal from 'sweetalert2';

const StarRating = ({libro}) => {
    const [rating, setRating] = useState(0);

    const [hover, setHover] = useState(0);


    const User = useSelector((state) => state.user);


    const handleClick = (value) => {
        Votar(value);
        setRating(value);
    };

    const handleMouseEnter = (value) => {
        setHover(value);
    };
    
    
    
    const Votar = async (value) => {
        // el id es el del libro
        const Datos = {
            d: 2,
            iduser: User.id,
            id: libro.element.id,
            estrellas: libro.element.estrellas,
            estre: rating
        };
        
        try {
            let resultado = await Consulta(Datos, 'info_libros.php');
            let data = resultado.data;
            if(data.mensaje){
                Swal.fire({
                    icon: 'warning',
                    title: '¡Alerta!',
                    text: data.mensaje,
                    confirmButtonText: 'Entendido'
                })

            }else{
                Swal.fire({
                    icon: 'success',
                    title: 'Guardado correctamente',
                    confirmButtonText: 'Entendido'
                })
            }



        } catch (error) {
            Swal.fire({
                icon: 'warning',
                title: '¡Alerta!',
                text: error,
                confirmButtonText: 'Entendido'
            })
            console.error("Error al votar:", error);
        }
    };

    const fullStar = (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
            <path d="M3.612 15.443c-.396.198-.86-.149-.746-.592l.83-4.73L.278 6.765c-.33-.314-.16-.888.283-.95l4.898-.696 2.19-4.387a.513.513 0 0 1 .927 0l2.19 4.387 4.898.696c.442.062.612.636.283.95l-3.522 3.356.83 4.73c.113.443-.35.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
    );


    const emptyStar = (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
        </svg>
    );

    return (
        <div className="star-rating">
            {[...Array(5)].map((_, index) => {
                const ratingValue = index + 1;
                return (
                    <span key={index} className="star-container m-3" style={{ cursor: 'pointer', color: ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9" }}>
                        <span
                            onClick={() => handleClick(ratingValue)}
                            onMouseEnter={() => handleMouseEnter(ratingValue)}
                        >
                            {ratingValue <= (hover || rating) ? fullStar : emptyStar}
                        </span>
                    </span>
                );
            })}
        </div>
    );
};

export default StarRating;
