import React, { useState } from "react";
import "../Buscadores/Buscador.css"
import ConsultaAdmin from "utils/ConsultaAdmin";
import Swal from "sweetalert2";
import URL from "URL";
import CryptoJS from "crypto-js";

const BuscadorLibros = ({ LibroBusqueda, setLibroBusqueda, setEstado, setVerLibro }) => {
    const [Busqueda, setBusqueda] = useState([])
    const [paginas, setpaginas] = useState()
    const [pagina_actual, setpagina_actual] = useState(1)
    const [datos_busqueda, setdatos_busqueda] = useState()

    const guardarstate = (dato) => {
        setLibroBusqueda({
            ...LibroBusqueda,
            libro: dato.element,
        });
        setEstado(3);
    }
    const CompartirLibro = async (e) => {

        let carpeta = CryptoJS.AES.encrypt(e.element.carpeta, 'B');
        let libro = CryptoJS.AES.encrypt(e.element.libro, 'B');
        let url = URL.local+"Verlibro?card="+carpeta+"&token="+libro;
        try {
            await navigator.clipboard.writeText(url);
            Swal.fire({
                icon: 'success',
                title: 'Link copiado en el porta papeles!',
                text: url
              })
        } catch (err) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'error de proceso'
              })
        }
    }
    const verLibro = (carpeta, libro) =>{
        setEstado(2);
        setVerLibro({
            libro: libro,
            carpeta : carpeta   
        });
    }
    const CambioPagina = (valor, accion) => {
        
        let val = 1;

        switch (accion) {
            //suma
            case 1:   
                if(valor <= paginas){
                    val = pagina_actual + valor;
                }
                break;
            //resta
            case 2:
                if(pagina_actual > 0){
                    val = pagina_actual - valor;
                    
                }
                break;
            //set 
            case 3:
                if(valor <= paginas){
                    val = valor;
                }
                
                break;
            default:
                if(valor <= paginas){
                    val = pagina_actual + valor;
                }
                break;

        }
        setpagina_actual(val);
        fetchData(datos_busqueda, 1, val);
    }
    const fetchData = async (busqueda, caso = 0, paginas_cambio = 0) => {
        let bus = '';
        let pagina_buscar = pagina_actual;
        switch (caso) {
            case 1:
            bus = datos_busqueda;
            pagina_buscar = paginas_cambio;
            setdatos_busqueda(bus);
                break;
        
            default:
                bus = busqueda.target.value;
                setdatos_busqueda(bus);
                break;
        }
        
        if (bus) {
            try {
                const DatosJson = { d: 2, nombre: bus, pagina: pagina_buscar, perPage: 1 };
                let response = await ConsultaAdmin( DatosJson ,'info_libros.php')
                let data = response.data;
                if (data.estado) {
                    setBusqueda(data.datos.datos);
                    setpaginas(data.datos.totalPaginas);
                    setpagina_actual(data.datos.paginaActual);
                }
                // Hacer algo con los datos recibidos
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                // Manejar el error apropiadamente, por ejemplo, mostrando un mensaje al usuario
            }
        }

    };
    const elementosPaginacion = [];

    for (let i = 1; i <= paginas; i++) {
        elementosPaginacion.push(
            <li onClick={()=>CambioPagina(i,3)} className={`page-item ${pagina_actual === i ? 'active' : ''}`} key={i}>
                <button className="page-link">{i}</button>
            </li>
        );
    }
    const Borrar_Libro = async (libro) => {
        Swal.fire({
            title: '¿Esta seguro de borrar este libro?',
            showDenyButton: true,
            confirmButtonText: `¡Si!`,
            denyButtonText: `No`,
          }).then(async (result)  =>  {
            if (result.isConfirmed) {
                try {
                    const DatosJson = { d: 3, id_libro: libro.element.id };
                    let response = await ConsultaAdmin( DatosJson ,'info_libros.php')
                    let data = response.data;
                    if (data.estado) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Libro eliminado con exito!',
                          })
                          fetchData(datos_busqueda, 1, pagina_actual);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al borrar el registro',
                            text: 'cod error: '+data
                          })
                    }
                    // Hacer algo con los datos recibidos
                } catch (error) {
                    console.error('Error al obtener los datos:', error);
                    // Manejar el error apropiadamente, por ejemplo, mostrando un mensaje al usuario
                }
            }
        })
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        Buscar libros
                        <input className="form-control m-2" name="Buscador" id="Buscador" type="text" onChange={(e) => fetchData(e)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col md-6">
                        {Busqueda ?
                            Busqueda.map((element, index) => (
                                <div className={`row alert alert-light option`} key={index}>
                                    <div className="col-md-12" >
                                        <div className="row">
                                            <div className="col-md-2 text-center">
                                                <img className="portada" src={element.portada} alt={element.Nombre} />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="row m-2">
                                                    <div className="col-md-2">
                                                        <h5>Nombre:</h5>{element.Nombre}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <h5>Genero:</h5>{element.Nombre_Genero}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <h5>Autor:</h5>{element.Nombre_autor}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row text-center">
                                                            <div className="col-md-3">
                                                                <svg onClick={() => verLibro(element.carpeta, element.libro)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="click optionsingle bi bi-eye-fill" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                                </svg>
                                                                <br />
                                                                ver
                                                            </div>
                                                            <div className="col-md-3">
                                                                <svg onClick={() => { Borrar_Libro({ element }) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="click optionsingle bi bi-trash" viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                </svg>

                                                                <br />
                                                                Borrar
                                                            </div>
                                                            <div className="col-md-3">
                                                                <svg onClick={() => { guardarstate({ element }) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="click optionsingle bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                                </svg>
                                                                <br />
                                                                Editar
                                                            </div>
                                                            <div className="col-md-3">
                                                                <svg onClick={() => { CompartirLibro({ element }) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="click bi bi-box-arrow-up" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1z"/>
                                                                    <path fillRule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708z"/>
                                                                </svg>
                                                                <br />
                                                                Compartir
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-2">
                                                    <div className="col-md-12">
                                                        <h5>introduccion:</h5>{element.intro}
                                                    </div>

                                                </div>
                                                <div className="row m-2">
                                                    <div className="col-md-12">
                                                        <h5>objetivo:</h5>{element.objetivo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : "....."}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${pagina_actual === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => CambioPagina(1, 2)} tabIndex="-1" aria-disabled="true">anterior</button>
                                </li>
                                {elementosPaginacion}
                                <li className={`page-item ${pagina_actual === paginas ? 'disabled' : ''}`}>
                                    <button className="page-link"  onClick={() => CambioPagina(1, 1)}>Siguiente</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuscadorLibros;