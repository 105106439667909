import React, { useState } from "react";
import axios from "axios";
import URL from "URL";
import "../Buscadores/Buscador.css"

const Buscador = ({ categorio, state, setstate }) => {
    const [Busqueda, setBusqueda] = useState([])
    const guardarstate = (dato) => {
        setstate({
            ...state,
            libro: dato.element,
        });
    }
    const fetchData = async (busqueda) => {
        let bus = busqueda.target.value;
        if (bus) {
            try {
                const DatosJson = JSON.stringify({ d: 1, Carpeta: categorio, Buscar: bus });
                const api = axios.create({ baseURL: URL.servidor });
                const response = await api.post("/api-php-react/admin/info_libros.php", DatosJson);
                let data = response.data;
                if (data.estado) {
                    setBusqueda(data.datos);
                }
                // Hacer algo con los datos recibidos
            } catch (error) {
                console.error('Error al obtener los datos:', error);
                // Manejar el error apropiadamente, por ejemplo, mostrando un mensaje al usuario
            }
        }

    };
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        {categorio ? <input className="form-control m-2" name="Buscador" id="Buscador" type="text" onChange={fetchData} />

                            : <h3>Ingrese la categoria</h3>}

                    </div>
                </div>
                <div className="row">
                    <div className="col md-6">
                        {Busqueda ?
                            Busqueda.map((element, index) => (
                                <div className={`row alert ${element === state.libro ? 'alert-success' : 'alert-light'} click option`} key={index}>
                                    <div className="col-md-12" onClick={() => { guardarstate({ element }) }}>
                                        {element}
                                    </div>
                                </div>
                            )) : "....."}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Buscador;