import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../../css/comunicaciones.css";
import { Create } from "services/agenda_intituto";
import Swal from "sweetalert2";
const AgregarAgenda = ({ volver }) => {
    const [Datos, setDatos] = useState();
    const { id_Col, id } = useSelector((state) => state.user);

    const Enviar = async (e) => {
        e.preventDefault();
        try {
            const res = await Create(Datos);
            let json = res.data
            console.log(json);
            if (json.estado) {
                Swal.fire({
                    icon: "success",
                    title: "Evento agendado correctamente con la id :" + json.id,
                    confirmButtonText: "Aceptar"
                }).then(() => {
                    // Llama a la función volver después de hacer clic en "Aceptar"
                    volver(0);
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al guardar el evento"
                });
            }
        } catch (error) {
            console.error("Error al crear evento:", error);
        }
    };
    const GuardarDatos = (e) => {
        setDatos({...Datos,
            id_col : id_Col,
            id_docente: id,
            [e.target.name] : e.target.value
        })
    }

    return (
        <div className="container">
            <form action="" className="caja-form shadow-sm" onSubmit={Enviar}>
                <div className="row">
                    <svg onClick={() => {
                        volver(0);
                    }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        fill="currentColor"
                        style={{ cursor: 'pointer' }}
                        viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                    <div className="col-md-12 text-center">
                        <h3>Alistamiento de eventos</h3>
                    </div>
                </div>
                <div className="row" style={{ padding: "5px", boxSizing: "border-box" }}>
                    <div className="col-md-6">
                        <label htmlFor="Nombre" className="form-label">Nombre evento:</label>
                        <input className="form-control" type="text" id="nombre" name="nombre" onChange={GuardarDatos} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="fecha" className="form-label">Fecha:</label>
                        <input className="form-control" type="datetime-local" id="fecha" name="fecha" onChange={GuardarDatos} required/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="detalle">Descripción</label>
                        <textarea onChange={GuardarDatos} id="texto" name="texto" className="form-control" style={{ width: "100%", boxSizing: "border-box" }} rows="10" required></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <button type="submit" className="btn btn-primary">Crear</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AgregarAgenda;
