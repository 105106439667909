import React from "react";
import "./style.css";

function Index({ children, onClose }) {
  const handleOnClose = (e) => {
    if (e.target.id === "backdrop-container") {
      onClose();
    }
    e.stopPropagation();
  };
  return (
    <div
      className="backdrop-container"
      id="backdrop-container"
      onClick={handleOnClose}
    >
      <div className="modal-container">
        <header className="modal-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </header>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
}

export default Index;
