/**
 * ==================================================
 * Sistema de planeación de recursos empresariales 
 * @author Enso-Learning
 * @copyright Copyright (c) 2022, Enso-Learning
 * @version 1.0 EDU_PLT
 * ==================================================
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import URL from "../../../URL";
import Swal from 'sweetalert2';
const CrearColegio = () => {
    const [estado, setestado] = useState(0)
    const [colegios, setcolegios] = useState([])
    const [form, setform] = useState([])
    const User = useSelector((state) => state.user);



    const GuardarDatos = (e) => {
        setform({
            ...form,
            [e.target.name]: e.target.value.trim()
        })
    }
    const Enviar = async (e) => {
        e.preventDefault();
        var Datos = {
            "d":7,
            "idColegio":form.idColegio,
            "Estudiantes":form.Estudiantes,
            "Curso":form.Curso,
            "Codigo":"reseller",
            "Dias":form.Dias,
            "Horas":form.Horas,
            "Materias":form.Materias,
            "Jornada":form.Jornada,
            "Ciclo":form.Ciclo
        }
        const consulta = await axios({
            method: "post",
            url: `${URL.servidor}/api-php-react/admin/Crud_admin.php`,
            data: Datos
        })
        if (consulta.data) {
            Swal.fire({
                icon: 'success',
                title: '¡Exitoso!',
                text: 'Curso creado con éxito',
                confirmButtonText: 'Entendido'
            }).then((result) => {
                if (result.isConfirmed) {
                    setestado(1)
                    document.getElementById("form-envio").reset(); 
                }
            })
        }
    }
    useEffect(() => {
        const TrearDatos = async () => {
            let idCurso = JSON.stringify({ d: 2, reseller: User.id });
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post(
                "/api-php-react/admin/Crud_admin.php",
                idCurso
            );
            const data = response.data;
            setcolegios(data);
        }
        TrearDatos();
    }, [estado, User]);
    return (
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={Enviar} id="form-envio">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Nombres del curso: </label>
                                    <input type="text" onChange={GuardarDatos} name='Curso' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Dias de clase: </label>
                                    <input type="number" onChange={GuardarDatos} name='Dias' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Horas de clase (Diarias): </label>
                                    <input type="number" name='Horas' onChange={GuardarDatos} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Cantidad de Materias: </label>
                                    <input type="number" name='Materias' onChange={GuardarDatos} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Jornada: </label>
                                    <select name="Jornada" id="Jornada" className='form-control' onChange={GuardarDatos}>
                                        <option value="">Seleccione</option>
                                        <option value="1">Tarde</option>
                                        <option value="2">Mañana</option>
                                        <option value="3">Noche</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Ciclo: </label>
                                    <select name="Ciclo" id="Ciclo" className='form-control' onChange={GuardarDatos}>
                                        <option value="">Seleccione</option>
                                        <option value="dark"    > Ciclo 3 </option>
                                        <option value="danger"  > Ciclo 2 </option>
                                        <option value="warning" > Ciclo 1 </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Estudiantes: </label>
                                    <input type="number" name='Estudiantes' onChange={GuardarDatos} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                <label for="exampleInputEmail1" className="form-label">Colegio: </label>
                                    <select name="idColegio" id="idColegio" className="form-control" onChange={GuardarDatos}>
                                        <option value="">Seleccione</option>
                                        {colegios.length > 0 && colegios[0].id ?
                                            colegios.map((colegio) =>
                                                <option value={colegio.id}>{colegio.nombreC}</option>
                                            ) :
                                            null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default CrearColegio;