import React from "react";
import URL from "URL.js";
import { getAll as getAllaulas } from "services/aulas";
import { getAll as getAllMaterias } from "services/Materias";
import "../../../css/curso.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAll as getAllCursos } from "services/courses";
import ConsultarDocentesColegio from "services/Docentes/ConsultarDocentes";
import axios from "axios";
import Swal from "sweetalert2";

function ClassroomView() {
  const [aulas, setaulas] = useState([])
  console.log("🚀 ~ file: ClassroomView.jsx:15 ~ ClassroomView ~ aulas:", aulas)
  const [materias, setmaterias] = useState()
  const [vista, setvista] = useState(1)
  const [cursos, setcursos] = useState();
  const [docentes, setdocentes] = useState();
  const [enviar, setenviar] = useState({ d: 4 });
  
  const Coordinador = useSelector((state) => state.user);

  useEffect(() => {
    if(Coordinador.id_Col){
      getAllaulas({ colegio: Coordinador.id_Col }).then((res) => {
        setaulas(res.data);
      });
    }
  }, [Coordinador.id_Col,vista]);

  useEffect(() => {
    getAllMaterias().then((res) => {
      setmaterias(res.data);
    });
    getAllCursos({ d: 1, id: Coordinador.id_Col }).then((res) => {
      setcursos(res.data);
    });
    ConsultarDocentesColegio({ colegio: Coordinador.id_Col }).then((res) => {
      setdocentes(res.data);
    });
  }, [vista, Coordinador]);

  const AgregarAula = () => {
    setvista(2)
  }
  const GuardarDatos = (event) => {
    if (event.target.name === 'cursos') {
      let dato = event.target.value;
      dato = dato.split('@');
      setenviar(prevState => ({
        ...prevState,
        NumeroCurso: dato[1],
        curso: dato[0],

      }));
    } else {
      setenviar(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    }


  }
  const GuardarAula = async (e) => {
    e.preventDefault();
    var Datos = enviar;
    const consulta = await axios({
      method: "post",
      url: `${URL.servidor}/api-php-react/info_cordinador.php`,
      data: Datos
    })
    if (consulta.data) {
      let data = consulta.data;
      if(data.status){
        Swal.fire({
          icon: 'success',
          title: '¡Exitoso!',
          text: data.mensaje,
          confirmButtonText: 'Entendido'
        }).then((result) => {
          if (result.isConfirmed) {
            setvista(1)
            setenviar({ d: 4 })
          }
        })
      }else{
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: data.mensaje,
          confirmButtonText: 'Entendido'
        })
      }
 
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "30%",
      }}
    >
      <div className="cont-header-usuario-coor">
        <div>
          <h4>Mis aulas </h4>
          <svg
            width="473"
            height="412"
            viewBox="0 0 473 412"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M217.392 289.071C146.236 222.559 147.325 361.617 76.5106 297.795C39.8429 205.118 71.3377 105.766 146.856 75.8871C222.375 46.0084 313.32 96.9171 349.988 189.595C386.655 282.273 318.48 383.563 217.392 289.071Z"
              fill="#00639A"
              fillOpacity="0.9"
            />
            <path
              d="M282.878 275.356C253.463 247.189 227.833 198.048 261.714 171.381C295.595 144.713 380.526 143.015 407.194 176.897C433.861 210.778 428.013 259.862 394.132 286.53C360.251 313.197 309.545 309.237 282.878 275.356Z"
              fill="#FFBB5E"
              fillOpacity="0.9"
            />
          </svg>
        </div>
        {vista === 1 ?
          <div className="d-flex justify-content-center align-items-center click_btn" onClick={AgregarAula}>
            <div className="btn-crear-use-cordi">+ Crear aula</div>
          </div> :
          <div className="d-flex justify-content-center align-items-center click_btn" onClick={() => setvista(1)}>
            <div className="btn-crear-use-cordi">Ver aulas</div>
          </div>}
      </div>
      <div className="cont-cursos-coord-v1">
        {vista === 1 ?
          <div className="row">
            <div className="col-md-12">
              <div className="cont-cursos-coord-v2">
                {aulas.estado ?
                  aulas.datos.map((aula) => (
                    <div className="card-aula">
                      <img style={{ width: '100%', height:'200px' }} alt= {aula.N_Materia}                        
                      src={`${URL.servidor}Archivos_u/iconos/${aula.NURL}`}
                      />
                      <h5>{aula.N_Materia}</h5>
                      <h6>Curso {aula.Curso_Nu}</h6>
                    </div>
                  ))
                  : 
                  <div>
                    {aulas.mensaje}
                  </div>}
              </div>
            </div>
          </div> : null}
        {vista === 2 ?
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={GuardarAula}>
                <div className="mb-3">
                  <h3>Configuración de aula</h3>
                  <label htmlFor="materias" className="form-label">Materia:</label>
                  <select name="materias" id="materias" className="form-select" onChange={GuardarDatos} required>
                    <option value="">Seleccione...</option>
                    {materias ?
                      materias.map((materia) => (
                        <option value={materia.id}>{materia.N_Materia}</option>
                      ))
                      : <option value="">No hay materias configuradas llamar a soporte</option>}
                  </select>
                  <div id="emailHelp" className="form-text">No puede tener dos aulas de la misma materia asociada a el mismo curso.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="cursos" className="form-label">curso:</label>
                  <select name="cursos" id="cursos" className="form-select" onChange={GuardarDatos} required>
                    <option value="">Seleccione...</option>
                    {cursos ?
                      cursos.map((curso) => (
                        <option value={curso.id + "@" + curso.Curso_Nu}>{curso.Curso_Nu}</option>
                      ))
                      : <option value="">No hay materias configuradas llamar a soporte</option>}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="docentes" className="form-label">Docentes:</label>
                  <select name="idDocente" id="docentes" className="form-select" onChange={GuardarDatos} required>
                    <option value="">Seleccione...</option>
                    {docentes ?
                      docentes.map((docente) => (
                        <option value={docente.id}>{docente.Nombre} {docente.apellido}</option>
                      ))
                      : <option value="">No hay materias configuradas llamar a soporte</option>}
                  </select>
                </div>
                <button type="submit" className="btn btn-primary">Crear</button>
              </form>
            </div>
          </div>
          : null
        }
      </div>
    </div>
  );
}

export default ClassroomView;
