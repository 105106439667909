import axios from "axios";
import URL from "../../URL";

const getAll = async (data) => {
  try {
    data.d = 2;
    const api = axios.create({ baseURL: URL.servidor });
    const request = await api.post(
      "/api-php-react/info_cordinador.php",
      JSON.stringify(data)
    );
    return request;
  } catch (error) {
    throw error;
  }
};

export default getAll;