import React, { useState, useEffect } from 'react';
import axios from 'axios';
import URL from '../../../URL.js';

import Swal from 'sweetalert2';
import Consulta from 'utils/Consulta.js';

const Colegio = () => {

    const [DatosRecibidos, setDatosRecibidos] = useState([])
    const [datosEscritos, setdatosEscritos] = useState({});

    const escritoenCampo = (e) => {
        setdatosEscritos({
            ...datosEscritos,
            [e.target.name]: e.target.value
        })
    }


    const agregarDias = async (idx) => {
        let datosEnviados = {
            d: 12,
            id: idx
        }
        let DatosJson = JSON.stringify(datosEnviados)
        const api = axios.create({ baseURL: URL.servidor });
        const response = await api.post('/api-php-react/info_admin.php', DatosJson);
        if (response.data) {
            Swal.fire({
                text: "Se ha agregado 30 dias más.",
                icon: "success"
            }).then(async (result) =>  {
                if (result.isConfirmed) {
                    const DatosJson = {d: 1};
                    let response = await Consulta( DatosJson ,'info_admin.php')
                    let data = response.data;
                    setDatosRecibidos(data)
                }
            })
        } else {
            Swal.fire({
                text: "Error no se ha podido agregar.",
                icon: "warning"
            })
        }
    }

    const agregarNinos = async (idx) => {
        if (datosEscritos.cupos) {
            let datosEnviados = {
                d: 11,
                id: idx,
                cupos: datosEscritos.cupos
            }
            let DatosJson = JSON.stringify(datosEnviados)
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post('/api-php-react/info_admin.php', DatosJson);
            if (response.data) {
                Swal.fire({
                    title: "Cupos para niños",
                    icon: "success",
                    text: "Se ha agregado " + datosEscritos.cupos + " niños."
                }).then((result) => {
                    if (result.isConfirmed) {
                    }
                })
            }
        } else {
            Swal.fire({
                title: "Campos vacios",
                icon: "warning"
            })
        }


    }


    useEffect(() => {
        const cargarUsuarios = async () => {
            const DatosJson = {d: 1};
            let response = await Consulta( DatosJson ,'info_admin.php')
            let data = response.data;
            setDatosRecibidos(data);
        }
        cargarUsuarios();
        //eslint-disable-next-line
    }, [])
    return (
        <div>
            <h4 className="ml-2" > Aquí podrás ver todos los Colegios de la plataforma </h4>
            {DatosRecibidos.length > 0 ? DatosRecibidos.map((data, index) => (
            <div className="shadow p-3 m-2" key={index}>
                <p><span className="h6">Nombre:</span> {data.nombreC}</p>
                <p><span className="h6">Contacto:</span> {data.contacto}</p>
                <p><span className="h6">Fecha de Creación:</span> {data.fecha_creación}</p>
                <p><span className="h6">Fecha de vencimiento:</span> {data.fecha_vencimiento}</p>
                <p><span className="h6">Código:</span> {data.id_cod}</p>
                <p><span className="h6">Información:</span> {data.info}</p>
                <p><span className="h6">Estudiantes registrados:</span> {data.cupos}</p>
                <p><span className="h6">Estado de pagos:</span> {data.pago === "1" ? <span>Activo</span> : <span>Inactivo</span>}</p>
                <p><span className="h6">Cupos máximo:</span> {data.Cupos_max}</p>
                {data.pago === 1 ? (
                    <div>
                        <div className="alert text-center alert-success">Valido</div>
                    </div>
                ) : (
                    <div>
                        <div className="alert text-center alert-danger">No valido</div>
                        <div className="d-flex flex-sm-row justify-content-center p-2">
                            <button
                                variant="contained"
                                type="submit"
                                color="primary"
                                name="submit"
                                className="text-center"
                                onClick={() => agregarDias(data.id)}
                            >
                                +30 días
                            </button>
                        </div>
                    </div>
                )}
                <div className="d-flex flex-sm-row justify-content-center p-2">
                    <input type="number" placeholder="Cupos máximo" className="form-control m-2 w-75" name="cupos" onChange={escritoenCampo} />
                    <button
                        variant="contained"
                        type="submit"
                        color="primary"
                        name="submit"
                        className="text-center"
                        onClick={() => agregarNinos(data.id)}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
            )) : null}

        </div>
    );
}

export default Colegio;