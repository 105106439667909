import React from 'react';
import Dashboard from '../Estudiante1-page/components/dashboard';


const NavbarCicloUno = () => {
    return (
        <div>
            <Dashboard />
        </div>
    );
}
 
export default NavbarCicloUno;