import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import Icfes from './Icfes';
import URL from '../../URL.js';
import '../css/actividades.css';
import Cookies from 'universal-cookie';

const Actividad = ({ idActividad }) => {

    const [Validacion, setValidacion] = useState(true)
    const [ArregloDeActividades, setArregloDeActividades] = useState([])
    const [Link, setLink] = useState("")
    const [ValidacionActividad, setValidacionActividad] = useState(true)
    const [puntos, setpuntos] = useState([]);
    const [respuntos, setrespuntos] = useState([]);
    console.log("🚀 ~ file: Actividad.js:17 ~ Actividad ~ respuntos:", respuntos)

    let CryptoJS = require("crypto-js")
    const cookies = new Cookies();


    const Desencriptar = (NombreCookie, Llave) => {
        let IdEncriptado = cookies.get(NombreCookie)
        let bytes = CryptoJS.AES.decrypt(IdEncriptado, Llave)
        let Datos = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        return Datos
    }

    let iduser = Desencriptar("iduser", "A")
    let idCurso = Desencriptar("idCurso", "A")
    const responder = (pregunta_id, e) => {
        setrespuntos({
            ...respuntos,
            [pregunta_id]: e.target.value
        })
        console.log("🚀 ~ file: Actividad.js:17 ~ Actividad ~ respuntos:", respuntos)
    }
    const responderMultiple = (pregunta_id, e) => {
        setrespuntos({
            ...respuntos,
            [pregunta_id]: e
        })
        console.log("🚀 ~ file: Actividad.js:17 ~ Actividad ~ respuntos:", respuntos)
    }

    useEffect(() => {
        const TraerDatos = async () => {
            console.log(idActividad)
            let idCurso = JSON.stringify({ idm: idActividad.id, d: 2, iduser: iduser, id: idActividad.id_acti })
            const api = axios.create({ baseURL: URL.servidor });
            const response = await api.post('/api-php-react/info_estudiante.php', idCurso);
            const data = response.data
            if (data.length > 0) {
                setArregloDeActividades(data)
                const h = data[0]
                const l = h.puntos
                const json = JSON.parse(l)
                setpuntos(json)
                let d = data[0]
                if (d.video !== undefined && d.video.length > 0) {
                    let com = d.video.substr(0, 23)
                    let link = d.video.substr(32, 100)
                    let linkfinal = `${com}/embed/${link}`
                    setLink(linkfinal)
                    setValidacionActividad(true)
                }
            } else if (data.mensaje) {
                setValidacionActividad(false)
                setArregloDeActividades([])
            }
        }
        TraerDatos()
        //eslint-disable-next-line
    }, []);
    console.log("🚀 ~ file: Actividad.js:66 ~ Actividad ~ puntos:", puntos)
    const Volver = () => {
        Swal.fire({
            title: '¿Seguro que quieres volver? perderás todas tus respuestas.',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `¡Si,volver!`,
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                setValidacion(true)
            }
        })
    }
    console.log(respuntos)
    const EnviarTarea = async (e) => {
        e.preventDefault()
        if (Object.keys(respuntos).length !== puntos.length) {
            console.log("🚀 ~ file: Actividad.js:94 ~ EnviarTarea ~ puntos:", puntos)
            Swal.fire({
                title: "info:",
                text:"faltan preguntas por contestar son:"+Object.keys(respuntos).length+"/"+puntos.length,
                icon: "info"
              });
        } else {
            if (document.getElementById("PDF").files.length > 0 && document.getElementById("comentario").value !== "") {
                let Archivo = document.getElementById("PDF").files[0]
                if (Archivo.type === "application/pdf" || Archivo.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || Archivo.type === "application/msword") {
                    const formData = new FormData();
                    formData.append('archivo', Archivo)
                    let res = await axios.post(`${URL.servidor}/api-php-react/Subir_archivo_tarea.php`, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    let data = res.data
                    if (data.status === "error") {
                        Swal.fire({
                            title: 'Error',
                            text: data.error,
                            icon: 'error'
                        })
                    } else if (data.status === "success") {
                        let comentario = document.getElementById("comentario").value
                        let datosEnviar = {
                            d: 3,
                            tarea: data.url,
                            comentario: comentario,
                            id_act: idActividad.id,
                            curso: idCurso,
                            iduser: iduser,
                            resjson: JSON.stringify(respuntos)
                        }

                        let DatosJson = JSON.stringify(datosEnviar)

                        const consulta = await axios({
                            method: "post",
                            url: `${URL.servidor}/api-php-react/info_estudiante.php`,
                            data: DatosJson
                        })

                        let datosRecibidos = consulta.data

                        if (datosRecibidos.id_tarea) {
                            Swal.fire({
                                icon: "success",
                                title: "Actividad enviada correctamente",
                                text: `tu actividad se registro con el id ${datosRecibidos.id_tarea}`
                            })
                            window.location.replace("EstudianteThreeActividades")
                        }

                        /*try {
                            let Configuracion = {
                                method : 'POST',
                                headers : {
                                    'Accept' : 'application/json',
                                    'Content-Type' : 'application/json'
                                },
                                body :  DatosJson
                            }
                            let res = await fetch( `${URL.servidor}/api-php-react/info_estudiante.php`, Configuracion)
                            let json = await res.json()
                            // let json = await res.text()
                            console.log(res)
                            console.log(json)
                            if(json.id_tarea){
                                Swal.fire({
                                    icon : "success",
                                    title : "Actividad enviada correctamente",
                                    text : `tu actividad se registro con el id ${json.id_tarea}`
                                })
                                window.location.replace("EstudianteThreeActividades")
                            }
        
                            if(json === true){
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Se ha creado correctamente',
                                })
                            }
                          } catch (error) {
                              console.log(error)
                          }*/
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrió un error al momento de enviar la actividad.',
                        })
                    }
                }
            } else {
                let comentario = document.getElementById("comentario").value
                let datosEnviar = {
                    d: 3,
                    tarea: false,
                    comentario: comentario,
                    id_act: idActividad.id,
                    curso: idCurso,
                    iduser: iduser,
                    resjson: JSON.stringify(respuntos)
                }

                let DatosJson = JSON.stringify(datosEnviar)

                const consulta = await axios({
                    method: "post",
                    url: `${URL.servidor}/api-php-react/info_estudiante.php`,
                    data: DatosJson
                })

                let datosRecibidos = consulta.data

                if (datosRecibidos.id_tarea) {
                    Swal.fire({
                        icon: "success",
                        title: "Actividad enviada correctamente",
                        text: `tu actividad se registro con el id ${datosRecibidos.id_tarea}`
                    })
                    window.location.replace("EstudianteThreeActividades")
                }
            }
        }

    }

    return (
        <div>
            {ValidacionActividad ?
                <div>
                    <form onSubmit={EnviarTarea} >
                        <div>
                            {ArregloDeActividades.map(data =>
                                <div key={data.id} className="m-2" >
                                    <p> <span className="h6" > Nombre: </span> {data.Nombre} </p>
                                    <p> <span className="h6" > Objetivo: </span> {data.objetivo} </p>
                                    <p> <span className="h6" > Responde las siguientes preguntas, si tienes dudas comunicate con tu docente: </span></p>
                                    <div>
                                        {data.Tipo === 2 ?
                                            <div>
                                                <h4>Esta actividad contiene un archivo pdf para realizarlo</h4>
                                                <a href={data.link}>LINK AL PDF</a>
                                            </div>
                                            : null}
                                        {data.imagen === 3 ?
                                            <div>
                                                <h4>Esta actividad contiene un imagen</h4>
                                                <img src={data.imagen} alt={"Enso Learning " + data.Nombre} className="w-50" />
                                            </div>
                                            : null}
                                        {data.video === 4 ?
                                            <div>
                                                <span className="w-100" >
                                                    <iframe
                                                        width="900"
                                                        height="500"
                                                        src={Link}
                                                        title="YouTube video player"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen="1" >
                                                    </iframe>
                                                </span>
                                            </div>
                                            : null}
                                        {data.ICFES === 5 ?
                                            <div>
                                                <div className="p-3" >
                                                    {Validacion ?
                                                        <div className="shadow p-3" >
                                                            <h3> Preguntas ICFES </h3>
                                                            <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas? </p>
                                                            <p> <span className="h6" > Tiempo: </span> 30 minutos </p>
                                                            <button variant="contained" color="secondary" className="mt-3" onClick={() => setValidacion(false)}  >
                                                                ¡Resolver!
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="mt-5" >
                                                            <div className="d-flex justify-content-start" id="botonVolver" >
                                                                <div className="pointer rounded-circle shadow  p-2" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" onClick={Volver} className="bi bi-arrow-left" viewBox="0 0 16 16">
                                                                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <Icfes />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                        {/* {data.juegos !== "NULL" ?
                                            <div className="p-3 shadow m-3" >
                                                <h3> Juegos Interactivos </h3>
                                                <p> Proximamente... </p>
                                            </div>
                                        : null} */}
                                    </div>
                                    {/* Opción multiple */}
                                    {data.tipo_s === 1 ?
                                        <div className='p-3 shadow'>
                                            {puntos && puntos.length > 0 ?
                                                puntos.map((punto, index) =>
                                                    <div className='col-md-12 p-3 shadow'>
                                                        <p><span style={{ color: 'darkred' }}>*</span>{index + 1}. {punto.pregunta}</p>
                                                        <div className={`col-md-12 pregunta-click shadow p-3 m-2 ${respuntos && respuntos[index] === 'A' ? 'alert alert-secondary' : 'alert alert-light'}`} onClick={() => responderMultiple(index, 'A')} ><span>A.</span><span>{punto.A}</span></div>
                                                        <div className={`col-md-12 pregunta-click shadow p-3 m-2 ${respuntos && respuntos[index] === 'B' ? 'alert alert-secondary' : 'alert alert-light'}`} onClick={() => responderMultiple(index, 'B')} ><span>B.</span><span>{punto.B}</span></div>
                                                        <div className={`col-md-12 pregunta-click shadow p-3 m-2 ${respuntos && respuntos[index] === 'C' ? 'alert alert-secondary' : 'alert alert-light'}`} onClick={() => responderMultiple(index, 'C')} ><span>C.</span><span>{punto.C}</span></div>
                                                        <div className={`col-md-12 pregunta-click shadow p-3 m-2 ${respuntos && respuntos[index] === 'D' ? 'alert alert-secondary' : 'alert alert-light'}`} onClick={() => responderMultiple(index, 'D')} ><span>D.</span><span>{punto.D}</span></div>
                                                    </div>
                                                )
                                                :
                                                null
                                            }
                                        </div>
                                        : null}
                                    {/* Preguntas Abiertas */}
                                    {data.tipo_s === 3 ?
                                        <div className='p-3 shadow'>
                                            {puntos && puntos.length > 0 ?
                                                puntos.map((punto, index) =>
                                                    <div className='col-md-12 p-3 shadow' key={index}>
                                                        <p><span style={{ color: 'darkred' }}>*</span>{index + 1}. {punto.pregunta}</p>
                                                        <textarea required onChange={(e) => responder(index, e)} style={{
                                                            width: '100%',   // Ocupa el 100% del ancho del contenedor
                                                            boxSizing: 'border-box',   // Incluye el padding en el ancho total
                                                            resize: 'vertical',   // Permite la redimensión vertical
                                                            minHeight: '50px',   // Altura mínima
                                                            maxHeight: '200px',   // Altura máxima (ajusta según tus necesidades)
                                                            overflowY: 'auto',   // Agrega barras de desplazamiento si es necesario
                                                        }}></textarea>
                                                    </div>
                                                )
                                                :
                                                null}
                                        </div>
                                        : null}
                                </div>
                            )}
                        </div>

                        <div className="p-3 shadow m-3" >
                            <h3> Subir archivos </h3>
                            <p> Si tu docente te ha dado instruciones, sube aquí el archivo que te esta pidiendo tu docente. (solo formato PDF) </p>
                            <input className="form-control m-2" id="PDF" type="file" />
                            <input className="form-control m-2" id="comentario" placeholder="Comentario de trabajo" />
                            <button className="btn btn-primary m-3" type="submit">
                                Subir Actividad
                            </button>
                        </div>
                    </form>
                </div>
                :
                <div className="alert alert-success" >
                    Felicidades, ¡Ya has agregado una respuesta a esta actividad!
                </div>
            }
        </div>
    );
}

export default Actividad;