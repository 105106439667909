/**
 * ==================================================
 * Sistema de planeación de recursos empresariales 
 * @author Enso-Learning
 * @copyright Copyright (c) 2022, Enso-Learning
 * @version 1.0 EDU_PLT
 * ==================================================
*/

import React,{useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import CryptoJS from 'crypto-js';
import InterfazAulas from './Aulas/interfazAulas';
import ViewAulas from './Aulas/viewAulas';
import Cookies from 'universal-cookie';
import Actividades from './Aulas/Activdades';
import AllPlanillas from './Aulas/Planillas/allPlanillas';
import Agenda from './Aulas/Planillas/Agenda';
import Notas from './Aulas/Planillas/Notas';
import IdentificadorMallas from './Mallas/IdentificadorMallas';
import UseMallas from './Mallas/UseMallas';
import CreateMallas from './Mallas/CreateMallas';
import CargarAreas from './Mallas/cargarAreas';
import ActividadLibro from './Aulas/actividadLibro';
import CreateActividades from './Aulas/ActividadesFolder/SelectorActividad';
import RecursosActividad from './Aulas/ActividadesFolder/crearActividades';
import TipoActividad from './Aulas/ActividadesFolder/tipoActividad';
import SeccionSelecion from './Aulas/ActividadesFolder/seccionSelecion';
import SeccionGrupos from './Aulas/ActividadesFolder/seccionGrupos';
import SeccionAbierta from './Aulas/ActividadesFolder/seccionAbierta';
import CalificarEva from '../views-dashboard/Evaluaciones/CalificarEva';
import BuscadorLibros from 'components/libros/BuscarLibrosGenericos';
import VerLibros from 'components/libros/VerLibros';


const MisAulas = () => {
    const dispatch = useDispatch()
    const [valid, setValid] = useState(false)
    const [LibroBusqueda, setLibroBusqueda] = useState({});
    const [VerLibro, setVerLibro] = useState({})

    const cookies =  new Cookies();

    const Desencriptar = (NombreCookie , Llave) => {
        let IdEncriptado =  cookies.get(NombreCookie)
        let bytes  = CryptoJS.AES.decrypt(IdEncriptado, Llave)
        let Datos = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        return Datos
    }

    

    const Docente = useSelector((state) => state.user);
    const idCol = Docente.id_col;
    const iduser = Desencriptar("iduser" , "A")

    if(!valid){
        dispatch({
            type : "@uploadDocente",
            docente : {
                id : iduser,
                colegio : idCol
            }
        })
        setValid(true)
    }
    
    const numberInterfazAula = useSelector(state => state.numberInterfazAula)

    const cambiarInterfaz = (num) => {
        dispatch({
          type : "@updateNumberInterfazAula",
          numberInterfazAula : num
        })
      }
    
    return (
        <div className='container-flex'>
            {numberInterfazAula === 1000 ? <IdentificadorMallas /> : null}
            {numberInterfazAula === 1001 ? <UseMallas /> : null}
            {numberInterfazAula === 1002 ? <CreateMallas /> : null}
            {numberInterfazAula === 1003 ? <CargarAreas /> : null}
            {numberInterfazAula === 0 ? <ViewAulas /> : null}
            {numberInterfazAula === 1 ? <InterfazAulas SetVerLibro={setVerLibro} SetEstado={cambiarInterfaz} /> : null}
            {numberInterfazAula === 2 ? <CalificarEva /> : null}
            {numberInterfazAula === 3 ? <Actividades /> : null}
            {numberInterfazAula === 4 ? <AllPlanillas /> : null}
            {numberInterfazAula === 5 ? 
             <div className='container'>
            <BuscadorLibros setLibroBusqueda={setLibroBusqueda} setEstado={cambiarInterfaz} setVerLibro={setVerLibro} />
            </div>
             : null}
            {numberInterfazAula === 6 ? <Notas />  : null}
            {numberInterfazAula === 7 ? <Agenda /> : null}
            {numberInterfazAula === 8 ? <ActividadLibro /> : null}
            
            {numberInterfazAula === 9 ? <CreateActividades /> : null}
            {numberInterfazAula === 10 ? < RecursosActividad/> : null}
            {numberInterfazAula === 11 ? < TipoActividad/> : null}
            {numberInterfazAula === 12 ? < SeccionSelecion/> : null}
            {numberInterfazAula === 13 ? < SeccionGrupos/> : null}
            {numberInterfazAula === 14 ? < SeccionAbierta/> : null}

            {numberInterfazAula === 15 ? 
                <div className='container'>
                    <VerLibros VerLibro={VerLibro} /> 
                </div>
            : null}
        </div>
    );
}


// lo que coloque fue la interfas 9 y 10
export default MisAulas;