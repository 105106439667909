import React from 'react';

const UpdateActividades = () => {


    return(
      <div>
          
      </div>
    )
}
 
export default UpdateActividades;