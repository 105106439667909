// src/StarRating.js
import React from 'react';
import './StarRating.css';

const StarRating = ({ sumaEstrellas, totalCalificaciones }) => {
    // Calcular el promedio
    const promedio = sumaEstrellas / totalCalificaciones;
    var estrellasLlenas = 0;
    var estrellasMedias = 0;
    var estrellasVacias = 0;
    if (sumaEstrellas > 0 && totalCalificaciones > 0) {
        // Mostrar estrellas según el promedio
        estrellasLlenas = Math.floor(promedio);
        estrellasMedias = (promedio - estrellasLlenas >= 0.5) ? 1 : 0;
        estrellasVacias = 5 - estrellasLlenas - estrellasMedias;
    }

    return (
        <div>
            <div id="estrellas">
                {Array(estrellasLlenas).fill().map((_, i) => (
                    <span key={`full-${i}`} className="star">★</span>
                ))}
                {estrellasMedias === 1 && (
                    <span className="star half">★</span>
                )}
                {Array(estrellasVacias).fill().map((_, i) => (
                    <span key={`empty-${i}`} className="star empty">★</span>
                ))}
            </div>
            <p id="calificacion-promedio">La calificación promedio es: {promedio ? promedio.toFixed(2) : 0} / 5</p>
        </div>
    );
};

export default StarRating;
