import React,{useEffect, useState} from 'react'
import axios from 'axios'
import URL from '../../URL'
import './css/segumineto.css'

const Anuncios = () => {


    return (
        <div>
            <div className='cont-info-home-acu'>
                <div>
                    <img className='img-acu-segui' src={`${URL.servidor}Archivos_u/iconos/segu-acti.svg`}/>
                    <svg id="cuadrados1-seguimiento-acu" width="245" height="326" viewBox="0 0 819 1087" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M358.912 813.751C353.389 813.751 348.912 818.228 348.912 823.751V985.365C348.912 990.888 353.389 995.365 358.912 995.365H531.414C536.937 995.365 541.414 990.888 541.414 985.365V823.751C541.414 818.228 536.937 813.751 531.414 813.751H358.912ZM369.673 824.788C365.254 824.788 361.673 828.37 361.673 832.788V975.324C361.673 979.743 365.254 983.324 369.673 983.324H521.714C526.132 983.324 529.714 979.743 529.714 975.324V832.788C529.714 828.37 526.132 824.788 521.714 824.788H369.673Z" fill="#0A2749" fillOpacity="0.6"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M455.695 905.06C450.172 905.06 445.695 909.537 445.695 915.06V1076.67C445.695 1082.2 450.172 1086.67 455.695 1086.67H628.198C633.721 1086.67 638.198 1082.2 638.198 1076.67V915.06C638.198 909.537 633.721 905.06 628.198 905.06H455.695ZM466.458 916.097C462.04 916.097 458.458 919.679 458.458 924.097V1066.63C458.458 1071.05 462.04 1074.63 466.458 1074.63H618.499C622.918 1074.63 626.499 1071.05 626.499 1066.63V924.097C626.499 919.679 622.918 916.097 618.499 916.097H466.458Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M434.406 507.131C428.883 507.131 424.406 511.609 424.406 517.131V640.408C424.406 645.931 428.883 650.408 434.406 650.408H566.273C571.796 650.408 576.273 645.931 576.273 640.408V517.131C576.273 511.609 571.796 507.131 566.273 507.131H434.406Z" fill="#0A2749" fillOpacity="0.2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M383.372 302.021C377.849 302.021 373.372 306.498 373.372 312.021V473.635C373.372 479.158 377.849 483.635 383.372 483.635H555.875C561.398 483.635 565.875 479.158 565.875 473.635V312.021C565.875 306.498 561.398 302.021 555.875 302.021H383.372Z" fill="#0A2749" fillOpacity="0.2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M247.238 722.442C241.715 722.442 237.238 726.92 237.238 732.442V894.056C237.238 899.579 241.715 904.056 247.238 904.056H419.741C425.264 904.056 429.741 899.579 429.741 894.056V732.442C429.741 726.92 425.264 722.442 419.741 722.442H247.238Z" fill="#0A2749" fillOpacity="0.2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M600.336 659.229C594.814 659.229 590.336 663.706 590.336 669.229V830.843C590.336 836.365 594.814 840.843 600.336 840.843H772.839C778.362 840.843 782.839 836.365 782.839 830.843V669.229C782.839 663.706 778.362 659.229 772.839 659.229H600.336ZM613.099 670.266C607.576 670.266 603.099 674.743 603.099 680.266V818.802C603.099 824.325 607.576 828.802 613.099 828.802H761.14C766.663 828.802 771.14 824.325 771.14 818.802V680.266C771.14 674.743 766.663 670.266 761.14 670.266H613.099Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M510.759 579.166C505.236 579.166 500.759 583.643 500.759 589.166V712.442C500.759 717.965 505.236 722.442 510.759 722.442H642.626C648.149 722.442 652.626 717.965 652.626 712.442V589.166C652.626 583.643 648.149 579.166 642.626 579.166H510.759ZM520.827 587.873C515.304 587.873 510.827 592.35 510.827 597.873V702.943C510.827 708.466 515.304 712.943 520.827 712.943H633.396C638.919 712.943 643.396 708.466 643.396 702.943V597.873C643.396 592.35 638.919 587.873 633.396 587.873H520.827Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M263.242 372.562C257.719 372.562 253.242 377.039 253.242 382.562V505.839C253.242 511.361 257.719 515.839 263.242 515.839H395.109C400.632 515.839 405.109 511.361 405.109 505.839V382.562C405.109 377.039 400.632 372.562 395.109 372.562H263.242ZM271.308 381.269C266.89 381.269 263.308 384.851 263.308 389.269V498.339C263.308 502.758 266.89 506.339 271.308 506.339H387.877C392.296 506.339 395.877 502.758 395.877 498.339V389.269C395.877 384.851 392.296 381.269 387.877 381.269H271.308Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M339.594 444.596C334.071 444.596 329.594 449.073 329.594 454.596V577.873C329.594 583.396 334.071 587.873 339.594 587.873H471.461C476.984 587.873 481.461 583.396 481.461 577.873V454.596C481.461 449.073 476.984 444.596 471.461 444.596H339.594ZM347.661 453.303C343.243 453.303 339.661 456.885 339.661 461.303V570.374C339.661 574.792 343.243 578.374 347.661 578.374H464.23C468.648 578.374 472.23 574.792 472.23 570.374V461.303C472.23 456.885 468.648 453.303 464.23 453.303H347.661Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M648.197 400.353C642.674 400.353 638.197 404.831 638.197 410.353V503.862C638.197 509.385 642.674 513.862 648.197 513.862H748.511C754.034 513.862 758.511 509.385 758.511 503.862V410.353C758.511 404.831 754.034 400.353 748.511 400.353H648.197ZM656.173 407.252C650.65 407.252 646.173 411.729 646.173 417.252V496.337C646.173 501.86 650.65 506.337 656.173 506.337H741.199C746.721 506.337 751.199 501.86 751.199 496.337V417.252C751.199 411.729 746.721 407.252 741.199 407.252H656.173Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M708.686 457.421C703.163 457.421 698.686 461.899 698.686 467.421V560.93C698.686 566.453 703.163 570.93 708.686 570.93H809C814.523 570.93 819 566.453 819 560.93V467.421C819 461.899 814.523 457.421 809 457.421H708.686ZM716.662 464.32C711.139 464.32 706.662 468.797 706.662 474.32V553.405C706.662 558.928 711.139 563.405 716.662 563.405H801.687C807.21 563.405 811.687 558.928 811.687 553.405V474.32C811.687 468.797 807.21 464.32 801.687 464.32H716.662Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M136.629 619.093C131.106 619.093 126.629 623.57 126.629 629.093V722.602C126.629 728.124 131.106 732.602 136.629 732.602H236.943C242.466 732.602 246.943 728.124 246.943 722.602V629.093C246.943 623.57 242.466 619.093 236.943 619.093H136.629ZM142.605 625.991C138.187 625.991 134.605 629.573 134.605 633.991V717.076C134.605 721.494 138.187 725.076 142.605 725.076H231.631C236.049 725.076 239.631 721.494 239.631 717.076V633.991C239.631 629.573 236.049 625.991 231.631 625.991H142.605Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M197.119 676.161C191.596 676.161 187.119 680.638 187.119 686.161V779.67C187.119 785.192 191.596 789.67 197.119 789.67H297.433C302.956 789.67 307.433 785.192 307.433 779.67V686.161C307.433 680.638 302.956 676.161 297.433 676.161H197.119ZM203.095 683.059C198.677 683.059 195.095 686.641 195.095 691.059V774.144C195.095 778.562 198.677 782.144 203.095 782.144H292.121C296.539 782.144 300.121 778.562 300.121 774.144V691.059C300.121 686.641 296.539 683.059 292.121 683.059H203.095Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 474.858C4.47715 474.858 0 479.335 0 484.858V578.367C0 583.889 4.47716 588.367 10 588.367H110.314C115.837 588.367 120.314 583.89 120.314 578.367V484.858C120.314 479.335 115.837 474.858 110.314 474.858H10ZM15.9761 481.756C11.5579 481.756 7.97613 485.338 7.97613 489.756V572.841C7.97613 577.26 11.5579 580.841 15.9761 580.841H105.002C109.42 580.841 113.002 577.26 113.002 572.841V489.756C113.002 485.338 109.42 481.756 105.002 481.756H15.9761Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M70.4902 531.926C64.9674 531.926 60.4902 536.403 60.4902 541.926V635.435C60.4902 640.957 64.9674 645.435 70.4902 645.435H170.805C176.327 645.435 180.805 640.957 180.805 635.435V541.926C180.805 536.403 176.327 531.926 170.805 531.926H70.4902ZM76.4664 538.824C72.0481 538.824 68.4664 542.406 68.4664 546.824V629.909C68.4664 634.327 72.0481 637.909 76.4664 637.909H165.492C169.91 637.909 173.492 634.327 173.492 629.909V546.824C173.492 542.406 169.91 538.824 165.492 538.824H76.4664Z" fill="#0092E3" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M539.412 141.908C533.889 141.908 529.412 146.385 529.412 151.908V282.999C529.412 288.522 533.889 292.999 539.412 292.999H679.561C685.084 292.999 689.561 288.522 689.561 282.999V151.908C689.561 146.385 685.084 141.908 679.561 141.908H539.412ZM548.03 151.09C543.612 151.09 540.03 154.672 540.03 159.09V274.982C540.03 279.4 543.612 282.982 548.03 282.982H671.829C676.247 282.982 679.829 279.4 679.829 274.982V159.09C679.829 154.672 676.247 151.09 671.829 151.09H548.03Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M619.927 217.871C614.404 217.871 609.927 222.348 609.927 227.871V358.961C609.927 364.484 614.404 368.961 619.927 368.961H760.077C765.6 368.961 770.077 364.484 770.077 358.961V227.871C770.077 222.348 765.6 217.871 760.077 217.871H619.927Z" fill="#0092E3" fillOpacity="0.2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M317.432 570.93C311.909 570.93 307.432 575.407 307.432 580.93V712.021C307.432 717.544 311.909 722.021 317.432 722.021H457.581C463.104 722.021 467.581 717.544 467.581 712.021V580.93C467.581 575.407 463.104 570.93 457.581 570.93H317.432Z" fill="#0092E3" fillOpacity="0.2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M175.981 279.947C170.458 279.947 165.981 284.424 165.981 289.947V413.224C165.981 418.746 170.458 423.224 175.981 423.224H307.848C313.371 423.224 317.848 418.746 317.848 413.224V289.947C317.848 284.424 313.371 279.947 307.848 279.947H175.981Z" fill="#0092E3" fillOpacity="0.3"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M358.912 0C353.389 0 348.912 4.47714 348.912 9.99999V141.091C348.912 146.613 353.389 151.091 358.912 151.091H499.061C504.584 151.091 509.061 146.613 509.061 141.091V10C509.061 4.47715 504.584 0 499.061 0H358.912ZM367.529 9.18223C363.111 9.18223 359.529 12.764 359.529 17.1822V133.073C359.529 137.492 363.111 141.073 367.529 141.073H491.328C495.747 141.073 499.328 137.492 499.328 133.073V17.1822C499.328 12.7639 495.747 9.18223 491.328 9.18223H367.529Z" fill="#0A2749" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M439.428 75.9626C433.905 75.9626 429.428 80.4398 429.428 85.9626V217.053C429.428 222.576 433.905 227.053 439.428 227.053H579.577C585.1 227.053 589.577 222.576 589.577 217.053V85.9626C589.577 80.4398 585.1 75.9626 579.577 75.9626H439.428ZM448.045 85.1448C443.627 85.1448 440.045 88.7265 440.045 93.1448V209.036C440.045 213.454 443.627 217.036 448.045 217.036H571.844C576.262 217.036 579.844 213.454 579.844 209.036V93.1448C579.844 88.7265 576.262 85.1448 571.844 85.1448H448.045Z" fill="#0092E3" fillOpacity="0.6"/>
                    </svg>

                </div>
                <div>
                    <h4>Seguimineto Actividades</h4>
                    <h5>Institucion educativa Julio Cortes I.E.D</h5>
                    <h5>Curso 402</h5>
                    <h6>
                        *En este espacio podras acompañar el desarrollo de las actividades del curso
                        enviados para calificacion desde la  plataforma del estudiante.
                    </h6>
                    <div className="input-buscador-acu1">
                        <input type="text" placeholder="Buscar en mensajes "/>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center cont-pri-actividad-docente">
                <div >
                    <div className="cont-tabla-actividad3">
                        <div>Nombre actividad</div>
                        <div>Area</div>
                        <div>grado</div>
                    </div>
                    <div className="my-4">   
                            <div   className="cont-datos-actividad-crear2">
                                <div>estado de la materia</div>
                                <div>Matematicas</div>
                                <div>3°</div>
                                <div className="btn-ver-actividad-crear1">
                                    <div   >en desarrolo</div>
                                    <div className="btn-ver-agregar-crear1">Agregar actividad</div>
                                </div>         
                            </div>
                    </div>
                </div>
           </div>
        </div>
    );
}
 
/*
 <div>
            {Anuncios.map(a => 
                <div className="p-3 m-2 shadow" key={a.id} >
                    <div>
                        <img src={`${URL.servidordos}${a.imagen.substr(20, 1000)}`} className="w-100" alt={a.titulo} />
                        <h5 className="mt-3" > <strong> {a.titulo} </strong> </h5>
                        <h6> {a.anuncio} </h6>
                        <p className="text-secondary" > {a.fecha} </p>
                    </div>        
                </div>    
            )}
        </div>
*/
export default Anuncios;