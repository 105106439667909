import React from "react";

const EstudianteItem = ({ nombre }) => (
    <div className="col-md-12 border border-primary p-2">
        {`Estudiante ${nombre}`}
    </div>
);

const MensajeriaCoordinador = ({ volver }) => {
    return (
        <div className="container shadow pb-5">
            <svg onClick={() => {
                console.log("SVG clickeado");
                volver(0);
            }}
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                fill="currentColor"
                style={{ cursor: 'pointer' }}
                viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
            </svg>
            <div className="row">
                <div className="col-md-8 border border-primary rounded">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Mensajes</h4>
                            <h5>Estudiante Nombre</h5>
                        </div>
                    </div>
                    <div className="row caja-chat">
                        <div className="col-md-12 border border-primary p-5">
                            <div className="row">
                                <div className="col-md-6 border border-success rounded-end">
                                    ¡Hola!
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 border border-primary rounded-start">
                                    ¡Hola!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 border border-primary">
                            <div className="row">
                                <div className="col-md-10 border-primary">
                                    <textarea
                                        className="form-control"
                                        name="mensaje"
                                        id="mensaje"
                                        rows="1"
                                    ></textarea>
                                </div>
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-send"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 border border-primary rounded">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((estudiante) => (
                        <EstudianteItem key={estudiante} nombre={`Juan Perez ${estudiante}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MensajeriaCoordinador;
