import React from 'react'

function InConstruction() {
  return (
    <div>
        <h1>Esta funcionalidad está en construcción</h1>
    </div>
  )
}

export default InConstruction;