import React from "react";

const Pregunta = ({ pregunta, numero }) => {
  console.log("🚀 ~ Pregunta ~ pregunta:", pregunta)
  return (
    <div className="p-md-5 p-2">
      <div>
        <h2 className="text-center text-orange text-break">
          <strong> Evaluacion </strong>
        </h2>
      </div>
      <div className="m-auto">
        <h5>
          <strong>{++numero}.</strong> {pregunta ? pregunta : 'error al cargar pregunta' }
        </h5>
      </div>
    </div>
  );
};

export default Pregunta;
