import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getById } from "services/courses";
import "../../../css/curso.css";
import ConsultarDocentesColegio from "services/Docentes/ConsultarDocentes";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import URL from "URL";

function CourseView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [estudiantes, setestudiantes] = useState({});
  const Colegio_id = useSelector((state) => state.user.id_Col);
  const ModificarDocenteCurso =  async () =>{
    ConsultarDocentesColegio({ colegio: Colegio_id }).then((res) => {
      let profesores = res.data;
      const inputOptions = {};
      profesores.forEach((profesor, index) => {
        index++;
        inputOptions[profesor.id] = "°"+index+" - "+profesor.Nombre+" "+profesor.apellido;
      });
      CambioDocente(inputOptions);
    });
  }
  const CambioDocente = async (inputOptions) => {
    try {
      const loadingSwal = Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
  
      const { value: docenteSeleccionado } = await Swal.fire({
        title: "Cambio de docente a cargo del curso",
        input: "select",
        inputOptions: inputOptions,
        inputPlaceholder: "Seleccione un Docente",
        showCancelButton: true,
      });
  
      if (docenteSeleccionado) {
        try {
          // Operación de guardado en la base de datos
            let Datos = {
              IdPro : docenteSeleccionado,
              id: id,
              d:6
            }
            const consulta = await axios({
              method : "post",
              url:`${URL.servidor}/api-php-react/info_Cursos.php`,
              data:Datos
          })
  
          console.log('Respuesta de la base de datos:', consulta.data);
  
          // Cerrar el cuadro de diálogo de carga
          loadingSwal.close();
  
          // Mostrar un mensaje de éxito
          Swal.fire({
            title: 'Éxito',
            text: 'La operación se completó exitosamente.',
            icon: 'success',
          });
        } catch (error) {
          console.error('Error al enviar la información a la base de datos', error);
          // Cerrar el cuadro de diálogo de carga
          loadingSwal.close();
  
          // Mostrar un mensaje de error
          Swal.fire({
            title: 'Error',
            text: 'Hubo un error al procesar la operación. Por favor, inténtalo nuevamente.',
            icon: 'error',
          });
        }
      } else {
        // El usuario canceló la selección
        console.log('Selección cancelada');
      }
    } catch (error) {
      console.error('Error al mostrar el cuadro de diálogo', error);
    }
  };  
  useEffect(() => {
    getById({ d: 0, id }).then((res) => {
      setCourse(res.data[0]);
    });
  }, [id]);
  useEffect(() => {
    getById({ d: 5, id_curso: id }).then((res) => {
      setestudiantes(res.data);
    });
  }, [id]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "30%",
      }}
    >
      <div style={{ width: "91.333%" }}>
        <div
          onClick={() => navigate("/AdminLearn/courses")}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            border: "2px solid #000",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            cursor: "pointer",
          }}
        >
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <g>
              <title>Layer 1</title>
              <line
                id="svg_2"
                y2="358"
                x2="331"
                y1="358"
                x1="334"
                stroke="#000"
                fill="none"
              />
              <line
                id="svg_3"
                y2="360"
                x2="41"
                y1="360"
                x1="42"
                stroke="#000"
                fill="none"
              />
              <line
                strokeLinecap="undefined"
                strokeLinejoin="undefined"
                id="svg_1"
                y2="14.93243"
                x2="7.29732"
                y1="6.14868"
                x1="14.86486"
                stroke="#00639A"
                fill="none"
              />
              <path
                id="svg_4"
                d="m16.75675,3.71625l-9.45943,11.21618"
                opacity="undefined"
                strokeLinecap="undefined"
                strokeLinejoin="undefined"
                strokeWidth="4"
                stroke="#00639A"
                fill="none"
              />
              <path
                id="svg_6"
                d="m7.02705,12.36487l9.3243,10.94591"
                opacity="undefined"
                strokeLinecap="undefined"
                strokeLinejoin="undefined"
                strokeWidth="4"
                stroke="#00639A"
                fill="none"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="cont-header-usuario-coor">
        <div style={{ height: "400px" }}>
          <h4>Información del curso {course.Curso_Nu}</h4>
          <svg
            width="450"
            height="450"
            viewBox="0 0 450 412"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M217.392 289.071C146.236 222.559 147.325 361.617 76.5106 297.795C39.8429 205.118 71.3377 105.766 146.856 75.8871C222.375 46.0084 313.32 96.9171 349.988 189.595C386.655 282.273 318.48 383.563 217.392 289.071Z"
              fill="#00639A"
              fillOpacity="0.9"
            />
            <path
              d="M282.878 275.356C253.463 247.189 227.833 198.048 261.714 171.381C295.595 144.713 380.526 143.015 407.194 176.897C433.861 210.778 428.013 259.862 394.132 286.53C360.251 313.197 309.545 309.237 282.878 275.356Z"
              fill="#FFBB5E"
              fillOpacity="0.9"
            />
          </svg>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "20px 0px",
          width: "91.333%",
        }}
      >
        <div>
          <h3>Docente acargo:</h3>
          <h4>{`${course.Nombres ? course.Nombres : "No"} ${course.Apellidos ? course.Apellidos : 'Asignado'}`}</h4>
          <span style={{cursor:'pointer'}} title="Modificar Docente" onClick={ModificarDocenteCurso}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
            </svg>
          </span>
        </div>
        <div>
          <h3>Cupos:</h3>
          <h4>{course.Can_Est}</h4>
        </div>
        <div>
          <h3>Cantidad de materias:</h3>
          <h4>{course.materias}</h4>
        </div>
        <div>
          <h3>Promedio:</h3>
          <h4>{course.promedio === null ? 0 : course.promedio}</h4>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", width: "91.3333%" }}
      >
        <div className="tabla-docentes-coordi" style={{ width: "100%" }}>
          <div className="titulo-tabla-docentes-coordi2">
            <div>Nombre del Estudiante</div>
            <div>Correo electronico</div>
            <div>Promedio</div>
            <div>Herramientas</div>
          </div>
          {estudiantes[0] ? estudiantes.map((estudiante, index) => (
            <div className="datos-docente-coordi2" key={index}>
              <div>{estudiante.Nombre} {estudiante.Apellido}</div>
              <div>{estudiante.Correo}</div>
              <div>2</div>
              <div className="btn-tabla-modifi-curso-coord">Modificar curso</div>
            </div>
          ))
            : <h3>No Hay Estudiantes</h3>}
        </div>
      </div>
    </div>
  );
}

export default CourseView;
