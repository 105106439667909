import React from 'react'

function vision() {
    return (
        <div>
           <h2 className="text-center font-weigth-bolder">Visión</h2>
           <div className="w-75 m-auto">
                <p>Todas las instituciones con lo último en tecnología y herramientas educativas en Colombia, que pueda ser posible el conocimiento en herramientas de tecnológicas.</p>
               
           </div>        </div>
    )
}

export default vision
