import React from 'react';
import Dashboard from '../Acudientes/Dashboard';
 
const NavbarAcudientes = () => {
    return (  
        <div>
            <Dashboard />
        </div>
    );
}
 
export default NavbarAcudientes;