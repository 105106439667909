import React from 'react';
import Volver from '../Aulas/volver';

const CreateMallas = () => {
  return (
    <div>
      <Volver num={1000} />
      <h6> Crea una malla </h6>
    </div>
  );
}

export default CreateMallas;