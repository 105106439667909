import React, { useState, useEffect, useMemo } from "react";
import URL from "URL";
import "../Buscadores/Buscador.css";
import Cookies from 'universal-cookie';
import Redirigir from "utils/redirigir";
import CryptoJS from "crypto-js";

const BuscadorLibros = ({ VerLibro }) => {
    const cookies = useMemo(() => new Cookies(), []);
    const [sessionValid, setSessionValid] = useState(false);
    const [id_sesion, setid_sesion] = useState(0);
    console.log("entra acacacac");
    const array_llaves = useMemo(() => [
        "Invitado no registrado", "Invitado", "Docente", "Acudiente", 
        "Coordinador", "Ciclo 1", "Ciclo 2", "Ciclo 3", "Super User"
    ], []);

    useEffect(() => {
        const estado = cookies.get('estado');

        if (!estado) {
            // Redirigir a la página de inicio de sesión si no hay estado
            const rutasNoPermitidas = [
                "DocentePage", "registro", "FormE",
                "FormA", "FormAA", "FormD", "adminformtwo", 
                "adminformthree", "adminformfour", "adminformfive", "adminpage"
            ];

            rutasNoPermitidas.forEach(ruta => {
                Redirigir(ruta, "");
            });
        } else {
            try {
                const bytes = CryptoJS.AES.decrypt(estado, 'B');
                const Sesion = parseInt(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));

                setid_sesion(Sesion);

                setSessionValid(true); // Establecer sesión como válida si no hay errores
            } catch (error) {
                console.error("Error descifrando el estado de la sesión:", error);
                // Redirigir a la página de inicio de sesión si hay un error al descifrar
                Redirigir("DocentePage", "");
            }
        }
    }, [cookies, setid_sesion, array_llaves, setSessionValid]);

    const url = `${URL.servidor}/Archivos_u/libros/${VerLibro.carpeta}/${VerLibro.libro}/${VerLibro.libro}.html`;
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>{array_llaves[id_sesion]}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <iframe src={url} width="100%" height="1000" title="Visor de Libros"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuscadorLibros;
